import React from 'react';
import TachieCarousel from '@Components/TachieCarousel';
import TextCarousel from '@Components/TextCarousel';

import reservationBtn from '@Assets/Images/reservationBtn.png';
import arrowPic from '@Assets/Images/arrow.png';

import { animated, useSpring, useSpringRef, config } from "@react-spring/web";

import { useDispatch,useSelector } from 'react-redux';
import { change } from '@Redux/ReducerSlices/BGControl';


import { useTimer } from 'react-timer-hook';
import { useNavigate } from 'react-router-dom';

const CombinedTachieWithText = (props) => {
    const navigate = useNavigate();
    const autoPlayInterval = 5;//秒
    const dispatch = useDispatch();
    let imageSrc = useSelector(state => state.BGControl.imageSrc);
    const tachieCarouselRef = React.useRef(null);
    const textCarouselRef = React.useRef(null);

    const _expiryTimestamp = new Date();
    _expiryTimestamp.setSeconds(_expiryTimestamp.getSeconds() + autoPlayInterval);

    React.useEffect(() => {
        console.log(imageSrc)
        if(imageSrc){
            pause();
        }else{
            restartTimer();
        }
    }, [imageSrc])
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        _expiryTimestamp,
        onExpire: () => {
            turnPage();
            setTimeout(() => {
                restart((new Date().setSeconds(new Date().getSeconds() + autoPlayInterval)));
            }, 1000);
        }
    });

    const restartTimer = () => {
        restart((new Date().setSeconds(new Date().getSeconds() + autoPlayInterval)));
    }

    React.useEffect(() => {
        dispatch(change(props.data.imgSrcs[0]));
        breathStyleRef.start({
            from: { opacity: 0.2 },
            to: { opacity: 1.0 },
            loop: { reverse: true },
            config: {
                mass: 1,
                tension: 0.2,
                friction: 0.2,
                clamp: true
            },
        })
    }, []);
    const breathStyleRef = useSpringRef();
    const breathStyle = useSpring({
        from: { opacity: 0.2 },
        ref: breathStyleRef
    })

    const turnPage = () => {
        tachieCarouselRef.current.nextPage();
        textCarouselRef.current.nextPage();
        const newBG = tachieCarouselRef.current.getDisplayingImage();
        dispatch(change(newBG));
    }

    const resetAutoPlayTimer = () => {
        restartTimer();
    }
    const stopAutoPlayTimer = () => {
        pause();
    }

    React.useEffect(() => {
        if (props.autoPlay) {
            resetAutoPlayTimer();
        } else {
            stopAutoPlayTimer();

        }
    }, [props.autoPlay])
    return (
        <div
            style={{ width: 1440, height: 720, position: 'fixed', marginTop: 8, marginLeft: 8 }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 288,
                    right: 196,
                    zIndex: 10,
                    userSelect: 'none',
                    cursor: 'pointer'
                }}
                onClick={() => { turnPage(); resetAutoPlayTimer() }}>
                <img src={arrowPic} />
            </div>
            <div style={{ left: 0, top: 600, position: 'absolute', zIndex: 3 }}>
                <animated.div style={breathStyle}>
                    <div
                        onClick={() => {
                            navigate('/reservation')
                        }}
                        style={{

                            width: 320,
                            transform: 'translate(-8px,0px)',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                        <img src={reservationBtn} />
                    </div>
                </animated.div>
            </div>
            <TachieCarousel
                ref={tachieCarouselRef}
                style={{ position: 'absolute', left: 320, zIndex: 2, top: -240 }}
                data={{
                    imgSrcs: [...props.data.imgSrcs],
                    tachieCateNames: [...props.data.tachieCateNames],
                    tachieCharaNames: [...props.data.tachieCharaNames]
                }} />

            <TextCarousel
                ref={textCarouselRef}
                style={{ position: 'relative', left: 0, transform: 'translate(0,-36px)', zIndex: 1 }}
                data={{
                    title: props.data.descTitle,
                    subTitle: props.data.descSubTitle,
                    descTexts: props.data.descTexts
                }}
            />
        </div>
    )
}

export default CombinedTachieWithText;