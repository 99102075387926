import React from 'react';
import { animated, useSpring, useSpringRef } from '@react-spring/web';
import SquareIcon from '@mui/icons-material/Square';
import pallette from '@Assets/Styles/pallette';
import useImagePreloader from "@Utils/useImagePreloader"
import { useDispatch } from 'react-redux';
import { show } from '@Redux/ReducerSlices/BGControl';
const testImage = require('@Assets/Images/pic1.webp');

const picStyle = {
    width: 800, height: 540, objectFit: 'cover',
    boxShadow: '0px 4px 12px #303030',
    //transform: 'perspective(500px) translate3d(0,0,4px)',
   // WebkitTransform: 'perspective(500px) translate3d(0,0,4px)',
}

const cardTextStyle = {
    marginTop: 16,
    paddingLeft: 36,
    width: '100%',
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}


const publicCardStyle = {
    width: 800,
    height: 600,
    backgroundColor: 'white',
    position: 'absolute',
    top: 32,
    boxShadow: '0px 0px 32px #000000f0',
    transformOrigin: '1000px center',
}

const styleAtPos0 = {
    left: -144,
    zIndex: 5,
    transform: 'perspective(500px) rotate3d(0,1,0,-0deg) translate3d(0,0,-0px)',
    WebkitTransform: 'perspective(500px) rotate3d(0,1,0,-0deg) translate3d(0,0,-0px)',
    opacity: 0,
    filter: 'brightness(0.7)'
}
//划走之后的css，位于左侧透明
const styleAtPos1 = {

    left: 32,
    zIndex: 4,
    transform: 'perspective(500px) rotate3d(0,1,0,-0deg) translate3d(0,0,-0px)',
    WebkitTransform: 'perspective(500px) rotate3d(0,1,0,-0deg) translate3d(0,0,-0px)',
    opacity: 1,
    filter: 'brightness(1)'
}
const styleAtPos2 = {
    left: 120,
    zIndex: 3,
    transform: 'perspective(500px) rotate3d(0,1,0,-8deg) translate3d(0,0,-12px)',
    WebkitTransform: 'perspective(500px) rotate3d(0,1,0,-8deg) translate3d(0,0,-12px)',
    opacity: 1,
    filter: 'brightness(0.7)'
}
const styleAtPos3 = {
    left: 208,
    zIndex: 2,
    transform: 'perspective(500px) rotate3d(0,1,0,-12deg) translate3d(0,0,-16px)',
    WebkitTransform: 'perspective(500px) rotate3d(0,1,0,-12deg) translate3d(0,0,-16px)',
    opacity: 1,
    filter: 'brightness(0.7)'
}
//这三张是实际展示的css
const styleAtPos4 = {
    left: 288,
    zIndex: 1,
    transform: 'perspective(500px) rotate3d(0,1,0,-16deg) translate3d(0,0,-20px)',
    WebkitTransform: 'perspective(500px) rotate3d(0,1,0,-16deg) translate3d(0,0,-20px)',
    opacity: 0,
    filter: 'brightness(0.7)'
}
//最后一张“预备状态”的css位于右侧隐藏


//const cardStyles = [styleAtPos0, styleAtPos1, styleAtPos2, styleAtPos3, styleAtPos4];

const cardStyles = [styleAtPos4, styleAtPos3, styleAtPos2, styleAtPos1, styleAtPos0];


let CardCarousel = (props, ref) => {
    const dispatch = useDispatch();
    const { imagesPreloaded } = useImagePreloader(props.data.imgSrcs)
       // console.log(imagesPreloaded)
    React.useImperativeHandle(ref, () => ({
        nextPage: () => {
            nextPage();
        },
        getDisplayingImage: () => {
            //console.log(props.data.imgSrcs[(dataReadPointer - 2) % props.data.imgSrcs.length])
            //return imgSrcs[index]
            return props.data.imgSrcs[(dataReadPointer - 2) % props.data.imgSrcs.length];
        }
    }));

    //翻页方法暴露给外部

    const [imgSrcs, setImgSrcs] = React.useState([null, null, null, null, null]);
    const [cardTexts, setCardTexts] = React.useState([null, null, null, null, null]);
    //内部建图片地址,描述文字Array
    React.useEffect(() => {
        const _imgSrcs = [...imgSrcs];
        const _cardTexts = [...cardTexts];
        for (let i = 0; i < 4; i += 1) {
            _imgSrcs[i] = props.data.imgSrcs[i % props.data.imgSrcs.length];
            _cardTexts[i] = props.data.cardTexts[i % props.data.cardTexts.length];
        }
        setImgSrcs(_imgSrcs);
        setCardTexts(_cardTexts);
        setReadPointer(3);
    }, [])
    //把前四位的图片地址填入（第五个“预备”位会在第一次翻页时加载）

    const [index, setIndex] = React.useState(0);

    //用于管理滚动状态的index

    const [dataReadPointer, setReadPointer] = React.useState(0);

    //点击查看大图
    const showImg=(value)=>{
        // console.log(value)
        // console.log(this.current.getDisplayingImage())
        // console.log((dataReadPointer - 2) % props.data.imgSrcs.length)
        //const newBG = props.data.imgSrcs[(dataReadPointer - 2) % props.data.imgSrcs.length];
        dispatch(show(imgSrcs[value]));
    }
    //设置一个用来从图片列表读值的指针，滚动播放用

    const nextPage = () => {
        let _index = index + 1;
        if (_index > 4) {
            _index = 0;
        }
        setIndex(_index);
        const _pointer = dataReadPointer + 1;
        setReadPointer(_pointer);
        const _imgSrcs = [...imgSrcs];
        _imgSrcs[(3 + _index) % 5] = props.data.imgSrcs[_pointer % props.data.imgSrcs.length];

        const _cardTexts = [...cardTexts];
        _cardTexts[(3 + _index) % 5] = props.data.cardTexts[_pointer % props.data.cardTexts.length];
        
        setImgSrcs(_imgSrcs);
        setCardTexts(_cardTexts);
        //翻页时改index，根据index判断现在最后一位是哪个卡片，根据指针改变其图片地址


        trans0Ref.start({
            from: cardStyles[(4 + index) % 5],
            to: cardStyles[(0 + index) % 5]
        });
        trans1Ref.start({
            from: cardStyles[(0 + index) % 5],
            to: cardStyles[(1 + index) % 5]
        });
        trans2Ref.start({
            from: cardStyles[(1 + index) % 5],
            to: cardStyles[(2 + index) % 5]
        });
        trans3Ref.start({
            from: cardStyles[(2 + index) % 5],
            to: cardStyles[(3 + index) % 5]
        });
        trans4Ref.start({
            from: cardStyles[(3 + index) % 5],
            to: cardStyles[(4 + index) % 5]
        })
        //分别触发所有卡片的动画
    }

    const trans0Ref = useSpringRef();
    const trans1Ref = useSpringRef();
    const trans2Ref = useSpringRef();
    const trans3Ref = useSpringRef();
    const trans4Ref = useSpringRef();


    const trans0 = useSpring({
        from: { ...styleAtPos0, ...publicCardStyle },
        ref: trans0Ref
    });
    const trans1 = useSpring({
        from: { ...styleAtPos4, ...publicCardStyle },
        ref: trans1Ref
    });
    const trans2 = useSpring({
        from: { ...styleAtPos3, ...publicCardStyle },
        ref: trans2Ref
    });
    const trans3 = useSpring({
        from: { ...styleAtPos2, ...publicCardStyle },
        ref: trans3Ref
    });
    const trans4 = useSpring({
        from: { ...styleAtPos1, ...publicCardStyle },
        ref: trans4Ref
    });

    //定义动画hook与ref

    const breathStyle = useSpring({
        from: { opacity: 1 },
        to: { opacity: 0.5 },
        loop: true
    })


    return (
        <div style={{
            //width: 1600,
            //backgroundColor: '#00ff0030',
            //height: 672,
            //position: 'fixed',
            userSelect: 'none',
            ...props.style,

        }} onClick={()=>{showImg(index)}} >

            <animated.div style={trans0}>
                
                <img src={imgSrcs[4]} style={{ ...picStyle }} />
                <div style={cardTextStyle}>
                    <SquareIcon style={{
                        color: pallette.limeGreen,
                        fontSize: 16,
                        marginRight: 4
                    }} />
                    {cardTexts[4]}
                </div>
            </animated.div>
            <animated.div style={trans1}>

                <img src={imgSrcs[3]} style={{ ...picStyle }} />
                <div style={cardTextStyle}>
                    <SquareIcon style={{
                        color: pallette.limeGreen,
                        fontSize: 16,
                        marginRight: 4
                    }} />
                    {cardTexts[3]}
                </div>
            </animated.div>
            <animated.div style={trans2}>

                <img src={imgSrcs[2]} style={{ ...picStyle }} />
                <div style={cardTextStyle}>
                    <SquareIcon style={{
                        color: pallette.limeGreen,
                        fontSize: 16,
                        marginRight: 4
                    }} />
                    {cardTexts[2]}
                </div>
            </animated.div>
            <animated.div style={trans3}>

                <img src={imgSrcs[1]} style={{ ...picStyle }} />
                <div style={cardTextStyle}>
                    <SquareIcon style={{
                        color: pallette.limeGreen,
                        fontSize: 16,
                        marginRight: 4
                    }} />
                    {cardTexts[1]}
                </div>
            </animated.div>
            {/* 第一张展示出来的图 */}
            <animated.div style={trans4} >
                <img src={imgSrcs[0]} style={{ ...picStyle }} />
                <div style={cardTextStyle}>
                    <SquareIcon style={{
                        color: pallette.limeGreen,
                        fontSize: 16,
                        marginRight: 4
                    }} />
                    {cardTexts[0]}
                </div>
            </animated.div>
            {/* <button onClick={() => {
                nextPage();
            }}>翻页</button> */}
        </div>
    )
}

CardCarousel = React.forwardRef(CardCarousel);
export default CardCarousel;