import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Box, AppBar, Toolbar, Typography, Button, ButtonGroup, IconButton, InputBase, Menu, MenuItem, Divider } from '@mui/material';
import { animated, useSpring, useSpringRef, config } from '@react-spring/web';
import { useSelector } from 'react-redux';

import pallette from '@Assets/Styles/pallette';

const headerStyle = {
    height: 64,
    display: 'flex', flexDirection: 'row', alignItems: 'center'
}

const labelHighlightedStyle = {
    height: '100%',
    width: 144,
    backgroundColor: pallette.limeGreen,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#000000',
    borderRadius: '0px 0px 4px 4px',
    fontSize: 18,
    fontFamily: 'SourceHanSans',
    userSelect: 'none',
    cursor: 'pointer'
}

const labelDefaultStyle = {
    height: '100%',
    width: 144,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#FFFFFF',
    borderRadius: '0px 0px 4px 4px',
    fontSize: 18,
    fontFamily: 'SourceHanSans',
    userSelect: 'none',
    cursor: 'pointer'
}

const NavigateLabel = (props) => {
    const [highlighted, setHighlighted] = React.useState(false);
    const labelTransRef = useSpringRef();
    const labelTrans = useSpring({
        from: { ...labelDefaultStyle },
        ref: labelTransRef
    })

    const highlight = () => {
        labelTransRef.start({
            from: { ...labelDefaultStyle },
            to: { ...labelHighlightedStyle },
            config: config.slow
        });
        setHighlighted(true);
    }
    const deHighlight = () => {
        labelTransRef.start({
            from: { ...labelHighlightedStyle },
            to: { ...labelDefaultStyle },
            config: config.slow
        });
        setHighlighted(false);
    }
    React.useEffect(() => {
        if (
            props.currentPath === props.path &&
            !highlighted
        ) {
            highlight();
        }
        if (
            props.currentPath !== props.path &&
            highlighted
        ) {
            deHighlight();
        }
    }, [props.currentPath])
    const navigate = useNavigate();
    return (
        <animated.div
            style={{
                ...labelTrans
            }}
            onClick={() => {
                navigate(props.path);
            }}
        >
            {props.label}
            <div style={{ height: 6 }} />
        </animated.div>
    )
}



const Header = (props) => {
    const [prevPath, setPrevPath] = React.useState('/');
    const location = useLocation();
    const config = useSelector(state => state.data.config);
    React.useEffect(() => {
        if (prevPath !== location.pathname) {
            if (location.pathname !== '/') {
                if (prevPath === '/') {
                    showHeader();
                }
            } else {
                hideHeader();
            }
            setPrevPath(location.pathname);
        }
    }, [location]);

    const headerTransRef = useSpringRef();
    const headerTrans = useSpring({
        from: {
            transform: 'translate(0px,-66px)',
            backgroundColor: '#00000000'
        },
        ref: headerTransRef
    });
    const showHeader = () => {
        headerTransRef.start({
            from: {
                transform: 'translate(0px,-66px)',
                backgroundColor: '#00000000'
            },
            to: {
                transform: 'translate(0px,0px)',
                backgroundColor: '#00000000'
            }
        })
    }

    const hideHeader = () => {
        headerTransRef.start({
            from: {
                transform: 'translate(0px,0px)',
                backgroundColor: '#00000000'
            },
            to: {
                transform: 'translate(0px,-66px)',
                backgroundColor: '#00000000'
            }
        })
    }



    return (
        <AppBar position="fixed" style={{ backgroundColor: 'transparent', boxShadow: "0 0 0 rgba(0,0,0,0)" }}>
            <animated.div style={{ ...headerStyle, ...headerTrans }}>
                <div style={{ flexGrow: 3 }} />
                <NavigateLabel label={'商业作品'} currentPath={location.pathname} path={'/commercial-works'} />
                <NavigateLabel label={'个人作品'} currentPath={location.pathname} path={'/original-works'} />
                {config.goodsPageVisible ? (
                    <NavigateLabel label={'在售周边'} currentPath={location.pathname} path={'/peripherals'} />
                ) : (null)}
                <NavigateLabel label={'稿件邀约'} currentPath={location.pathname} path={'/reservation'} />
                <NavigateLabel label={'作者信息'} currentPath={location.pathname} path={'/'} />
                <div style={{ flexGrow: 1 }} />
            </animated.div>
        </AppBar>
    )
}
export default Header;


