const pallette = {
    black30: '#303030',
    whiteF0: '#F0F0F0',
    grayBD: "#BDBDBD",
    gray8E: "#8E8E8E",
    limeGreen: 'rgba(215,255,0,1)',
    orange: "#D7532C",
    orange1: 'rgba(215,83,44,0.3)',
    orange2: 'rgba(215,83,44,0.5)',
    orange3: 'rgba(215,83,44,0.9)',
}

export default pallette;