export function sendMail(body) {
  const requestBody = {
    service_id: "PPMSelling",
    template_id: "TSArtistWebsite",
    user_id: "user_Nz762tKWkr203TH90kNqr",
    accessToken: "948ff5d2f9e7096b149ad9c0dc642fb8",
    template_params: {
      ReservationType: body.ReservationType,
      Budget: body.Budget,
      Deadline: body.Deadline,
      Contact: body.Contact,
      Desc: body.Desc,
      AttachmentUrl: body.AttachmentUrl
    },
  };
  return new Promise(function (resolve, reject) {
    fetch("/smtp", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        console.log("res::", res);
        resolve(res.json());
      })
      .catch((e) => {
        console.log("err::", e);
        reject(e);
      });
  });
}
