import React from 'react';

import PageScroll from 'react-page-scroller';

import shopItemBase from '@Assets/Images/shopItemBase.png';
import peripheralsBG from '@Assets/Images/peripheralsBG.png';



import { animated, useSpring, useSpringRef, config } from "@react-spring/web";

import PageScrollIndicator from '@Components/PageScrollIndicator_MO';

import CombinedCardWithText from '@Components/CombinedCardWithText';
import CombinedTachieWithText from '@Components/CombinedTachieWithText';

import pallette from '@Assets/Styles/pallette';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";

import { dummyWords, dummySentences, dummyParagraphs } from '@Utils/DummyText';

import SquareIcon from '@mui/icons-material/Square';

import { useDispatch, useSelector } from 'react-redux';
import { change } from '@Redux/ReducerSlices/BGControl';





const ShopItemCell = (props) => {
  return (
    <div
      onClick={() => {
        window.open(props.data.link);
      }}

      style={{
        height: 120,
        width: 108,
        borderRadius: 8,
        backgroundImage: `url(${shopItemBase})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        userSelect: 'none',
        cursor: 'pointer',
        margin: 24
      }}>
      <div style={{
        height: 108,
        width: 108,
        backgroundImage: `url(${props.data.imgUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        transform: 'translate(-16px,-16px)',
        boxShadow: '4px 4px 16px #000000a0'
      }}>
      </div>
      <div style={{
        flexGrow: 1,
        paddingLeft: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        textAlign: 'left',
        color: 'black',
        fontFamily: 'SourceHanSans',
        transform: 'translate(0px,-12px)',
      }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SquareIcon style={{
            color: pallette.limeGreen,
            fontSize: 8,
            marginRight: 4,
            marginTop: 2
          }} />
          <div style={{ fontSize: 10 }}>
            {props.data.name}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 12 }}>
          <div style={{ fontSize: 6, display: 'flex', flexDirection: 'row' }}>
            {props.data.tags.map((tag, i) => {
              return <>{`#${tag}\xa0`}</>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const ShopItemPlaceHolder = () => {
  return (
    <div style={{
      height: 108,
      width: 108,
      margin: 16
    }} />
  )
}
const ShopItemRow = (props) => {
  const [itemData, setItemData] = React.useState([null, null, null, null]);
  React.useEffect(() => {
    const _itemData = [null, null, null, null];
    for (let i = 0; i < props.data.length; i += 1) {
      _itemData[i] = props.data[i];
    }
    setItemData(_itemData);
  }, [props.data]);
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignContent:'center',
      justifyContent: 'center'
    }}>
      {/* <div style={{ flexGrow: 1 }} /> */}
      {itemData.map((item, i) => {
        if (item) {
          return <ShopItemCell data={item} />
        } else {
          return <ShopItemPlaceHolder />
        }
      })}
      <div style={{ flexGrow: 1 }} />
    </div>
  )
}


const ShopList = (props) => {
  return (
    <div
      style={{ position: 'fixed', width:'100%' }}
    >
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        {/* <div style={{ flexGrow: 1 }} /> */}
        <ShopItemRow
          data={props.row0Data}
        />
        <ShopItemRow
          data={props.row1Data}
        />
        {/* <div style={{ flexGrow: 1 }} /> */}
      </div>
    </div>
  )
}


let PageBlock = (props, ref) => {
  const [focused, setFocused] = React.useState(true);
  const [autoPlay, setAutoPlay] = React.useState(true);
  const cutInStyleRef = useSpringRef();
  const cutInStyle = useSpring({
    ref: cutInStyleRef
  });
  const row0Data = [];
  const row1Data = [];
  for (let item of props.data.goods) {
    switch (item.pos) {
      case 1:
        row0Data[0] = { name: item.name, tags: item.tag.split(/[\,\，]/).map((item, i) => (item)), imgUrl: item.image, link: item.url };
        break;
      case 2:
        row0Data[1] = { name: item.name, tags: item.tag.split(/[\,\，]/).map((item, i) => (item)), imgUrl: item.image, link: item.url };
        break;
      case 3:
        row0Data[2] = { name: item.name, tags: item.tag.split(/[\,\，]/).map((item, i) => (item)), imgUrl: item.image, link: item.url };
        break;
      case 4:
        row0Data[3] = { name: item.name, tags: item.tag.split(/[\,\，]/).map((item, i) => (item)), imgUrl: item.image, link: item.url };
        break;
      case 5:
        row1Data[0] = { name: item.name, tags: item.tag.split(/[\,\，]/).map((item, i) => (item)), imgUrl: item.image, link: item.url };
        break;
      case 6:
        row1Data[1] = { name: item.name, tags: item.tag.split(/[\,\，]/).map((item, i) => (item)), imgUrl: item.image, link: item.url };
        break;
      case 7:
        row1Data[2] = { name: item.name, tags: item.tag.split(/[\,\，]/).map((item, i) => (item)), imgUrl: item.image, link: item.url };
        break;
      case 8:
        row1Data[3] = { name: item.name, tags: item.tag.split(/[\,\，]/).map((item, i) => (item)), imgUrl: item.image, link: item.url };
        break;
    }
  }
  React.useImperativeHandle(ref, () => ({
    cutIn: () => {
      setAutoPlay(true);
      setFocused(true);
      setTimeout(() => {
        cutInStyleRef.start({
          from: { opacity: 0 },
          to: { opacity: 1 }
        })
      }, 500)

    },
    cutOut: () => {
      setAutoPlay(false);
      cutInStyleRef.start({
        from: { opacity: 1 },
        to: { opacity: 0 }
      });
      setTimeout(() => {
        setFocused(false);
      }, 500)
    }
  }));

  return (
    (focused) ? (
      <animated.div style={cutInStyle}>
        <div style={{ height: 20 }} />
        <ShopList
          row0Data={row0Data}
          row1Data={row1Data}
        />
      </animated.div>
    ) : (
      <div style={{
        height: 960
      }}>

      </div>
    )

  )
}

PageBlock = React.forwardRef(PageBlock);




export default () => {
  const dispatch = useDispatch();


  const _data = useSelector(state => state.data.peripherals);
  const config = useSelector(state => state.data.config);
  const data = [..._data].sort((a, b) => (a.pos - b.pos));
  const pageRefs = React.useRef([]);

  React.useEffect(() => {
    pageRefs.current = pageRefs.current.slice(0, data.length);
  }, [data])


  React.useEffect(() => {
    dispatch(change(config.goodsBG));
  }, [])
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [scrollLock, setScrollLock] = React.useState(false);

  const pageDown = () => {
    const _index = currentIndex + 1;
    if (_index < data.length && !scrollLock) {
      pageRefs.current[_index].cutIn();
      pageRefs.current[currentIndex].cutOut();
      setCurrentIndex(_index);
      setScrollLock(true);
      setTimeout(() => {
        setScrollLock(false);
      }, 1500);
    }
  }

  const pageUp = () => {
    const _index = currentIndex - 1;
    if (_index >= 0 && !scrollLock) {
      pageRefs.current[_index].cutIn();
      pageRefs.current[currentIndex].cutOut();
      setCurrentIndex(_index);
      setScrollLock(true);
      setTimeout(() => {
        setScrollLock(false);
      }, 1500);
    }
  }


  const pageTo = (toIndex) => {
    const _index = toIndex;
    if (_index >= 0 && !scrollLock) {
      pageRefs.current[_index].cutIn();
      pageRefs.current[currentIndex].cutOut();
      setCurrentIndex(_index);
      setScrollLock(true);
      setTimeout(() => {
        setScrollLock(false);
      }, 1500);
    }
  }
  return (
    <div style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems:'flex-end'
    }}>

      <div style={{
        width: '60%', height: 60,
        display: 'flex',
        flexDirection: 'column',
        alignItems: "flex-end",
        justifyContent: 'flex-end',
        marginTop: -48
      }}>

        <PageScrollIndicator
          onScrollUp={() => {
            pageUp();
          }}
          onScrollDown={() => {
            pageDown();
          }}
          pageTo={pageTo}
          labels={data.map((item, i) => (item.name))}
          currentIndex={currentIndex}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >

        <ReactScrollWheelHandler
          upHandler={() => {
            pageUp();

          }}
          downHandler={() => {
            pageDown();
          }}
        >
          <PageScroll
            renderAllPagesOnFirstRender={true}
            // containerWidth={1440}
            // containerHeight={960}
            blockScrollDown={true}
            blockScrollUp={true}
            customPageNumber={currentIndex}
          >
            {
              data.map((item, i) => {
                let blockData = item;
                let BlockComponent;
                BlockComponent = <PageBlock ref={el => pageRefs.current[i] = el} data={blockData} key={`pageBlock${i}`} />;
                return BlockComponent;
              })
            }
          </PageScroll>
        </ReactScrollWheelHandler>

      </div>

    </div>
  )
}