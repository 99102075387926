import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { change } from '@Redux/ReducerSlices/BGControl';

import { useSpring, useSpringRef, animated, config } from '@react-spring/web';

import pallette from '@Assets/Styles/pallette';

import introBG from '@Assets/Images/IntroBG.jfif';



const contentContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:-80
  //backgroundColor: '#FF303030'
}

const titleContainerStyle = {
  width: 540,
  //height: 360,
  //backgroundColor: '#30ff3030',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  fontFamily: 'SansThin',
  color: pallette.limeGreen,
  lineHeight: 0.8,
  letterSpacing: 2,
  //fontStrech:'[0,]',
  fontSize: 88,
  textAlign: 'center'
}

const descContainerStyle = {
  width: 300,
  height: '100%',
  //backgroundColor: '#3030ff30',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',



}

const descTextStyle = {
  fontFamily: 'SourceHanSerif',
  color: '#FFFFFF',
  fontSize: 12,
  textAlign: 'center',
  marginTop: 18,
  marginBottom: 18
}

const startButtonStyle = {
  marginBottom: 10,
  fontFamily: 'SansThin',
  color: pallette.limeGreen,
  fontSize: 28,
  textAlign: 'center'
}

const Splash = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const config = useSelector(state => state.data.config);
  React.useEffect(() => {
    dispatch(change(config.splashBG));
  }, []);

  const titleTrans = useSpring({
    from: {
      transform: 'translate(-120px,0px)',
      opacity: 0.0
    },
    to: {
      transform: 'translate(0px,0px)',
      opacity: 1.0
    },
    clamp: true,
    delay: 200,
    config: config.molasses
  });

  const descTrans = useSpring({
    from: {
      transform: 'translate(-120px,0px)',
      opacity: 0.0
    },
    to: {
      transform: 'translate(0px,0px)',
      opacity: 1.0
    },
    clamp: true,
    delay: 1000,
    config: config.molasses
  });

  //const startButtonTransRef = useSpringRef();
  const startButtonTrans = useSpring({
    from: {
      transform: 'translate(-120px,0px)',
      opacity: 0.0
    },
    to: {
      transform: 'translate(0px,0px)',
      opacity: 1.0
    },
    clamp: true,
    delay: 1800,
    config: config.molasses,
    //ref: startButtonTransRef,
    // onRest: () => {
    //     console.log('fadein complete');
    //     // setTimeout(() => {

    //     // }, 300);
    // }
  });

  const breatheTrans = useSpring({
    from: { opacity: 0.2 },
    to: { opacity: 1.0 },
    loop: { reverse: true },
    delay: 2600,
    config: {
      mass: 1,
      tension: 0.2,
      friction: 0.2,
      clamp: true,
    }
  })

  return (
    <div style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      userSelect: 'none'
    }}>
      <div style={{ flexGrow: 1 }} >
      </div>

      <div style={contentContainerStyle} >
        <div style={
          titleContainerStyle
        }>
          <animated.div style={titleTrans} className="moniqa-display-salt">
            Welcome,<br />
            visitor.
          </animated.div>
        </div>
        <div style={descContainerStyle} >
          <animated.div style={{ ...descTextStyle, ...descTrans }}>
            {config.splashText.split('\n').map((t) => {
              return ([t, <div style={{ height: 4 }} />]);
            })}
          </animated.div>
          <animated.div style={{ ...startButtonStyle, ...startButtonTrans }} >
            <animated.div
              onClick={() => {
                navigate('/commercial-works')
              }}
              style={{ cursor: 'pointer', ...breatheTrans }}
              className="moniqa-display-salt"
            >
              TAP TO START
            </animated.div>
          </animated.div>
        </div>
      </div>

      <div style={{ flexGrow: 1 }} >
      </div>
    </div >
  )
}

export default Splash;