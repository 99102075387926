import React from 'react';

import { Input } from 'antd';

import Calendar from 'rc-calendar';
import calendarLocale from 'rc-calendar/lib/locale/zh_CN';
import 'rc-calendar/assets/index.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircleIcon from '@mui/icons-material/Circle';
import LeftArrowIcon from '@mui/icons-material/ArrowLeftOutlined';
import RightArrowIcon from '@mui/icons-material/ArrowRightOutlined';




import request from 'umi-request';

import moment from 'moment';

import { animated, useSpring, useSpringRef, config } from "@react-spring/web";



import ReactScrollWheelHandler from "react-scroll-wheel-handler";


import SquareIcon from '@mui/icons-material/Square';

import pallette from '@Assets/Styles/pallette';
import '@Assets/Styles/antdOverrides.css';

//import 'antd/dist/antd.css';


import { useDispatch, useSelector } from 'react-redux';
import { change } from '@Redux/ReducerSlices/BGControl';
import peripheralsBG from '@Assets/Images/peripheralsBG.png';

import sendBtnBase from '@Assets/Images/sendBtn.png';
import sentBtnBase from '@Assets/Images/sentBtn.png';
import uploadBtnBase from '@Assets/Images/uploadBtn.png';
import modalBase from '@Assets/Images/successModalPicBase.png';
import useViewport from '@Utils/UseViewport';


import { sendMail } from "@Utils/SMTPService";

import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const mainContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  //backgroundColor: '#FF000030'
}

const leftContainerStyle = {
  width: 540,
  height: 720,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  transform: 'translate(0px,-20px)'

}
const rightContainerStyle = {
  width: 720,
  height: 720,
  //backgroundColor: '#0000ff30',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center'
}


const rowContainerStyle = {
  width: 640,
  height: 32,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: 40
}

const rowLabelStyle = {
  width: 144,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: 12,
  paddingBottom: 4,
  color: 'white',
  fontFamily: 'SourceHanSerif',
  fontSize: 20
}

const inputStyle = {
  width: 440,
  height: 32,
  color: 'black',
  fontSize: 20,
  textIndent: 1,
  paddingLeft: 24,
  fontFamily: 'SourceHanSans',
  backgroundColor: '#C0C0C0'
}

const buttonStyle = {
  height: 72,
  width: 210,
  borderRadius: 8,
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'SourceHanSans',
  color: 'black',
  cursor: 'pointer'
}

const hintTextStyle = {
  height: 72,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'left',
  color: '#606060',
  fontFamily: 'SourceHanSans'
}


const InputBlock = (props) => {
  return (
    <div style={{
      ...rowContainerStyle
    }}>
      <div style={{
        ...rowLabelStyle
      }}>
        {props.label}
      </div>
      <div style={{ userSelect: 'contain' }}>
        <input
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
          style={{
            ...inputStyle
          }} />
      </div>
    </div>
  )
}


let SuccessModal = (props, ref) => {
  const { width: windowWidth, height: windowHeight } = useViewport();
  const [maskVisible, setMaskVisible] = React.useState(false);
  const maskStyle = {
    position: "absolute",
    height: windowHeight,
    width: windowWidth,
    backgroundColor: '#00000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transformOrigin: '50% 100%',
    transform: 'scale(0,0)',
    opacity: 0,
    zIndex: 10
  }
  const modalTransRef = useSpringRef();
  const modalTrans = useSpring({
    from: { ...maskStyle },
    ref: modalTransRef
  })

  const show = () => {
    setMaskVisible(true);
    modalTransRef.start({
      from: { opacity: 0, transform: 'scale(0, 0)' },
      to: { opacity: 0.95, transform: 'scale(1,1)' }
    })
  }


  const hide = () => {
    setMaskVisible(false);
    modalTransRef.start({
      from: { opacity: 0.95, transform: 'scale(1,1)' },
      to: { opacity: 0, transform: 'scale(0,0)' }
    })
  }
  React.useImperativeHandle(ref, () => ({
    show: () => {
      show();
    },
    hide: () => {
      hide();
    }
  }));
  return (<>

    <div style={{
      height: maskVisible ? '200%' : 0,
      width: maskVisible ? '200%' : 0,
      zIndex: 9,
      top: -0.5 * windowHeight,
      left: -0.5 * windowWidth,
      position: 'absolute',
      backgroundColor: '#00000080'

    }}>

    </div>
    <animated.div style={{
      ...modalTrans
    }}
    // onClick={() => { hide(); }}
    >
      <div style={{
        height: 480,
        width: 800,
        backgroundImage: `url(${modalBase})`,
        borderRadius: 8,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
      }}>
        <div style={{
          position: 'relative',
          top: 0,
          left: 800 - 128,
          width: 128,
          height: 128,
          cursor: 'pointer'
        }}
          onClick={() => { hide(); }}

        />
      </div>
    </animated.div>
  </>

  )
}

SuccessModal = React.forwardRef(SuccessModal);

export default () => {
  const modalRef = React.useRef();
  const hiddenFileInputRef = React.useRef();
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [uploadedFileNames, setUploadedFileNames] = React.useState([]);
  const dispatch = useDispatch();

  const [typeInput, setTypeInput] = React.useState('');
  const [deadlineInput, setDeadlineInput] = React.useState('');
  const [budgetInput, setBudgetInput] = React.useState('');
  const [contactInput, setContactInput] = React.useState('');
  const [descInput, setDescInput] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [hasSentEmail, setHasSentEmail] = React.useState(false);

  const [periodOffset, setPeriodOffset] = React.useState(0);

  const { width: windowWidth, height: windowHeight } = useViewport();

  const data = useSelector(state => state.data.reservations);
  const config = useSelector(state => state.data.config);
  const calRef = React.useRef(null);
  React.useEffect(() => {
    dispatch(change(config.reservationBG));
  }, [])


  const getPeriodText = (offset) => {
    const now = moment();
    now.add(Math.floor(offset), 'M');
    //now.add(1, 'd');
    
    const month = now._d.getMonth();
    const year = now._d.getFullYear()
    // const flag = now._d.getDate() > 15 ? ("下半月") : ("上半月");
    // return (`${month + 1}月\xa0\xa0${flag}`);
    return (`${year}年${month + 1}月`);
  }
  const checkPeriodBusy = (offset) => {
    const now = moment();

    let freeDays
    const startDate = moment();
    if (offset > 0) {
      freeDays = 28
      startDate.set('date', 1)
    } else {
      freeDays = 28 - now._d.getDate();
      startDate.set('date', now._d.getDate())
    }
    startDate.add(Math.floor(offset), 'M');
    //startDate.add(1, 'd');
    if (startDate._d.getDate() === 30) {
      startDate.add(1, 'M');
      startDate.set('date', 1);
    }
    const endDate = moment(startDate).add(freeDays, 'd');
    const _startDate = startDate.format('YYYY-MM-DD');
    const _endDate = endDate.format('YYYY-MM-DD');
    if (calRef.current) {
      calRef.current.setValue(startDate);

      //setSelectedDate(startDate);
    }
    for (let day of data) {
      if (
        moment(day).isBetween(_startDate, _endDate, undefined, '[]')
      ) {
        freeDays -= 1;
      }
    }
    //console.log(offset, _startDate, _endDate, freeDays)
    if (freeDays >= 20) {
      return 0;
    } else if (freeDays >= 10) {
      return 1;
    } else {
      return 2;
    }
  }
  const getRecentFreePeriod = () => {
    let i = 0;
    let output = -1;
    while (output < 0) {
      const checkingPeriodStatus = checkPeriodBusy(i);
      if (checkingPeriodStatus === 0) {
        output = i;
      } else {
        i += 1;
      }
    }
    return output;
  }
  const getCurrentOffset = () => {
    let offset = periodOffset
    return offset
  }
  const checkDateBusy = (date) => {
    const _date = date.format('YYYY-MM-DD');
    if (data.indexOf(_date) >= 0) {
      return true;
    } else {
      return false;
    }
  }


  const uploadImage = async () => {
    let result = {
      success: false,
      imageUrl: "",
      message: {}
    }
    let formData = new FormData();
    formData.append("file", uploadedFiles[0]);
    const response = await request
      .post(`/api/upload`, {
        requestType: 'form',
        body: formData
      }
      ).catch((e) => { console.log(e) });
    if (response && response.statusCode == 200) {
      result.imageUrl = `https://${response.Location}`;
      result.success = true;
    } else {
      result.success = false;
      result.message = response;
    }
    return result;
  };


  const onSubmit = async () => {
    //modalRef.current.show();
    if (typeInput && deadlineInput && budgetInput && contactInput && !hasSentEmail) {
      setLoading(true);
      const fileUploadResult = await uploadImage();
      if (fileUploadResult.success || descInput) {
        sendMail({
          ReservationType: typeInput,
          Budget: budgetInput,
          Deadline: deadlineInput,
          Contact: contactInput,
          Desc: descInput,
          AttachmentUrl: fileUploadResult.imageUrl
        })
          .then((res) => {
            setHasSentEmail(true);
            modalRef.current.show();
            setTypeInput('');
            setDeadlineInput('');
            setBudgetInput('');
            setContactInput('');
            setDescInput('');
            setUploadedFiles([]);
            setUploadedFileNames([]);
          })
          // TODO:这个地方就算发信成功了接口也会反一个ParseError，怪
          .catch((e) => {
            setHasSentEmail(true);
            modalRef.current.show();
            setTypeInput('');
            setDeadlineInput('');
            setBudgetInput('');
            setContactInput('');
            setDescInput('');
            setUploadedFiles([]);
            setUploadedFileNames([]);
          });
      } else {
        setLoading(false)
        alert("附件上传失败，请重试");
        return;
      }
    } else {
      if (hasSentEmail) {
        alert("请勿重复操作");
      } else {
        alert("请输入完整的信息");
      }
    }
  }

  const PeriodSticker = (props) => {
    let sticker = <></>;
    const currentStatus = checkPeriodBusy(props.offset);
    switch (currentStatus) {
      case 0:
        sticker = (
          <div style={{
            height: 48,
            flexGrow: 1,
            backgroundColor: 'white',
            marginTop: 20,
            borderRadius: 8,
            boxShadow: '4px 4px 16px #00000060',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'hidden'
          }}>
            <div style={{
              height: 48,
              width: 4,
              backgroundColor: '#41e68590'
            }} />
            <div style={{
              flexGrow: 1,
              textAlign: 'left',
              fontFamily: 'SourceHanSans',
              marginLeft: 16
            }} >
              {getPeriodText((props.offset))}
            </div>
            <div style={{
              marginRight: 16,
              fontFamily: 'SourceHanSans',
              color: '#41e68590',
              display: 'flex',
              alignItems: 'center',
            }}>
              {/* 空闲 */}
              <CircleIcon style={{ height: 10 }} />
            </div>
          </div>
        );
        break;
      case 1:
        sticker = (
          <div style={{
            height: 48,
            flexGrow: 1,
            backgroundColor: 'white',
            marginTop: 20,
            borderRadius: 8,
            boxShadow: '4px 4px 16px #00000060',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'hidden'
          }}>
            <div style={{
              height: 48,
              width: 4,
              backgroundColor: '#f6ca00'
            }} />
            <div style={{
              flexGrow: 1,
              textAlign: 'left',
              fontFamily: 'SourceHanSans',
              marginLeft: 16
            }} >
              {getPeriodText((props.offset))}
            </div>
            <div style={{
              marginRight: 16,
              fontFamily: 'SourceHanSans',
              color: '#f6ca00',
              display: 'flex',
              alignItems: 'center',
            }}>
              {/* 一般 */}
              <CircleIcon style={{ height: 10 }} />
            </div>
          </div>
        );
        break;
      case 2:
        sticker = (
          <div style={{
            height: 48,
            flexGrow: 1,
            backgroundColor: 'white',
            marginTop: 20,
            borderRadius: 8,
            boxShadow: '4px 4px 16px #00000060',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'hidden'
          }}>
            <div style={{
              height: 48,
              width: 4,
              backgroundColor: '#FF303090'
            }} />
            <div style={{
              flexGrow: 1,
              textAlign: 'left',
              fontFamily: 'SourceHanSans',
              marginLeft: 16
            }} >
              {getPeriodText((props.offset))}
            </div>
            <div style={{
              marginRight: 16,
              fontFamily: 'SourceHanSans',
              color: '#FF303090',
              display: 'flex',
              alignItems: 'center',
            }}>
              {/* 忙碌 */}
              <CircleIcon style={{ height: 10 }} />
            </div>
          </div>
        );
        break;
    }
    return sticker
  }

  return (
    <div style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column'
    }}>

      <SuccessModal ref={modalRef} />
      <div style={{ flexGrow: 1 }} >
      </div>
      <div style={{ ...mainContainerStyle }} >


        <div style={{
          ...leftContainerStyle
        }}>
          <div
            className="moniqa-display-salt"
            style={{
              color: pallette.limeGreen,
              fontSize: 144,
              fontFamily: 'SansThin',
              marginBottom: -72,
              letterSpacing: 4
            }}>
            SCHEDULE
          </div>
          <div style={{
            width: 420,
            borderRadius: 8,
            boxShadow: '8px 8px 24px #00000060',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 2
          }}>
            <div style={{ flexGrow: 1, padding: 36 }}>
              <div style={{
                textAlign: 'left',
                fontFamily: 'SourceHanSans',
                fontSize: 18
              }}>
                当前档期
              </div>
              <PeriodSticker offset={periodOffset} />
              <div style={{
                width: '100%',
                height: 36,
                marginTop: -40,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <div
                  onClick={() => {
                    const _offset = periodOffset - 1;
                    setPeriodOffset(_offset);
                  }}
                  style={{
                    cursor: 'pointer',
                    userSelect: 'none'
                  }}>
                  {periodOffset === 0 ? (<></>) : (
                    <LeftArrowIcon style={{ fontSize: 32, marginLeft: -32 }} />
                  )}
                </div>
                <div
                  onClick={() => {
                    const _offset = periodOffset + 1;
                    setPeriodOffset(_offset);
                  }}
                  style={{
                    cursor: 'pointer',
                    userSelect: 'none'
                  }}>
                  <RightArrowIcon style={{ fontSize: 32, marginRight: -32 }} />
                </div>
              </div>
              <div
                onClick={() => {
                  const recentFreePeriod = getRecentFreePeriod();
                  setPeriodOffset(recentFreePeriod);
                }}

                style={{
                  height: 48,
                  flexGrow: 1,
                  backgroundColor: 'white',
                  marginTop: 20,
                  borderRadius: 8,
                  boxShadow: '4px 4px 16px #00000060',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  overflow: 'hidden',
                  userSelect: 'none',
                  cursor: 'pointer'
                }}>
                <div style={{
                  height: 48,
                  width: 4,
                  backgroundColor: pallette.limeGreen
                }} />
                <div style={{
                  flexGrow: 1,
                  textAlign: 'left',
                  fontFamily: 'SourceHanSans',
                  marginLeft: 16
                }} >
                  查看最近空闲档期
                </div>
                <div style={{
                  marginRight: 16,
                  fontFamily: 'SourceHanSans',
                  color: 'gray'
                }}>
                  {/* <HelpOutlineIcon style={{
                                        marginTop: 4
                                    }} /> */}
                </div>
              </div>
            </div>
            <div style={{ height: 36 }} />
            <Calendar
              ref={calRef}

              onChange={e => {
                //console.log('change', moment().startOf('month'))
                let offset = e.diff(moment().startOf('month'), 'months')
                //console.log(e.format('YYYY-MM-DD'), offset)
                // const recentFreePeriod = getRecentFreePeriod();
                setPeriodOffset(offset);
              }}

              locale={calendarLocale}
              showDateInput={false}
              dateRender={(e) => {
                const isBusy = checkDateBusy(e);
                return (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 24,
                    userSelect: 'none',
                    backgroundColor: e.isSameOrBefore(moment().add(-1, 'd')) ? (
                      "#C0C0C0"
                    ) : (
                      isBusy ? ('#FF303090') : ('transparent')
                    ),
                    // border: selectedDate.format('YYYY-MM-DD') === e.format('YYYY-MM-DD') ? ('solid 1px #DC143C') : ('none')
                  }}>
                    {e._d.getDate()}
                  </div>
                );
              }}
              // rc-calendar-cell
              style={{
                borderRadius: 0,
                backgroundColor: 'transparent',
                boxShadow: '0px 0px 0px #00000000',
                width: 420 - 48,
                margin: 24,
                border: 0
              }} />
          </div>


        </div>



        <div style={{
          ...rightContainerStyle
        }}>

          {/* 右侧标题 */}
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}>
            <div style={{
              color: 'white',
              fontSize: 64,
              fontFamily: 'SourceHanSerif',
              textAlign: 'left',
              width: 280,
            }}>
              稿件邀约
            </div>
            <div style={{
              color: 'white',
              fontSize: 18,
              fontFamily: 'SourceHanSerif',
              //marginLeft: 8,
              marginBottom: 10,
              textAlign: 'left',
              width: 400
            }}>
              1.暂时不接受非商业项目的邀约<br></br>
              2.无具体邀约内容，仅为入库邀请，可随意填写
            </div>
          </div>
          <div style={{ height: 24 }} />
          <InputBlock
            disabled={loading}
            placeholder={'请简述您的需求，e.g.2~3人美宣插图'}
            label={'约稿类别'}
            value={typeInput}
            onChange={(e) => { setTypeInput(e.target.value) }}
          />
          <InputBlock
            disabled={loading}
            placeholder={'左侧档期红色范围为无法受理新邀约的时间段'}
            label={'期望截稿日'}
            value={deadlineInput}
            onChange={(e) => { setDeadlineInput(e.target.value) }}
          />
          <InputBlock
            disabled={loading}
            placeholder={'请以RMB为单位填写数字'}
            label={'价格预算'}
            value={budgetInput}
            onChange={(e) => { setBudgetInput(e.target.value) }}
          />
          <InputBlock
            disabled={loading}
            placeholder={'请填写您的邮箱或QQ'}
            label={'联系方式'}
            value={contactInput}
            onChange={(e) => { setContactInput(e.target.value) }}
          />
          <InputBlock
            disabled={loading}
            placeholder={'请输入补充说明'}
            label={'补充资料'}
            value={descInput}
            onChange={(e) => { setDescInput(e.target.value) }}
          />



          <div style={{
            ...rowContainerStyle,
            height: 72
          }}>
            <div style={{
              ...rowLabelStyle
            }}>

            </div>

            <div style={{
              display: 'flex', flexGrow: 1, height: 72, marginRight: 16, flexDirection: 'row', alignItems: 'center'
            }}>
              <div
                onClick={() => { hiddenFileInputRef.current.input.click(); }}
                style={{
                  ...buttonStyle,
                  backgroundImage: `url(${uploadBtnBase})`,
                  backgroundSize: 'cover'
                }}>
                <div style={{ fontSize: 22 }}>上传附件</div>
                <div style={{ fontSize: 12 }}>(推荐.PDF格式，支持.pptx/ .doc)</div>
              </div>
              <div style={{
                ...hintTextStyle
              }}>
                您可以直接上传需求brief文档，<br />
                建议您提供项目介绍文件以便更好<br />
                理解您的需求
              </div>
            </div>
          </div>

          {uploadedFiles.length > 0 && (
            <div style={{
              ...rowContainerStyle,
              height: 'auto',
              marginTop: 8
            }}>
              <div style={{
                ...rowLabelStyle
              }}>

              </div>
              <div style={{
                display: 'flex', flexGrow: 1, height: 72, marginRight: 16, flexDirection: 'row', alignItems: 'center'
              }}>
                <div style={{
                  ...hintTextStyle
                }}>
                  已上传：<br />
                  {uploadedFileNames.map((file) => (file.name))}
                </div>
              </div>
            </div>
          )}
          <Input
            multiple={true}
            maxLength={1}
            style={{ display: "none" }}
            type="file"
            id="input"
            files={uploadedFiles}
            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
            onChange={(event) => { setUploadedFiles(event.target.files); setUploadedFileNames([{ name: event.target.value.split('\\').pop() }]) }}
            ref={hiddenFileInputRef} />
          <div style={{ height: 24 }} />

          <div style={{
            ...rowContainerStyle,
            height: 72
          }}>
            <div style={{
              display: 'flex', flexGrow: 1, height: 72, marginRight: 16, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'
            }}>
              <div style={{
                ...hintTextStyle,
                textAlign: 'right',
                justifyContent: 'flex-end',
                marginRight: 16
              }}>
                请确保您的信息填写无误，感谢您的耐心！
              </div>
              {loading ? (
                <div
                  style={{
                    ...buttonStyle,
                    backgroundImage: `url(${sendBtnBase})`,
                    backgroundSize: 'cover'
                  }}>
                  <div style={{ fontSize: 28, marginLeft: 64 }}>发送中...</div>
                </div>
              ) : (
                hasSentEmail ? (
                  <div
                    onClick={() => {
                      //onSubmit();
                    }}
                    style={{
                      ...buttonStyle,
                      backgroundImage: `url(${sentBtnBase})`,
                      backgroundSize: 'cover'
                    }}>
                    <div style={{ fontSize: 28, marginLeft: 64 }}>已发送</div>
                  </div>
                ) : (

                  <div
                    onClick={
                      () => {
                        onSubmit();
                      }
                    }
                    style={{
                      ...buttonStyle,
                      backgroundImage: `url(${sendBtnBase})`,
                      backgroundSize: 'cover'
                    }}>
                    <div style={{ fontSize: 28, marginLeft: 64 }}>确认发送</div>
                  </div>
                )
              )}

            </div>
          </div>

        </div>
      </div>
      <div style={{ flexGrow: 1 }}>

      </div>
    </div >
  )
}