import React from 'react';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import horizontalLine from '@Assets/Images/horizontalLine.png';
import horizontalFadeMask from '@Assets/Images/horizontalFadeMask.png';

import { animated, useSpring, useSpringRef } from "@react-spring/web";


let LabelDiv = (props, ref) => {
  const labelTransRef = useSpringRef();
  const labelTrans = useSpring({
    from: {
      height: 40,
      paddingTop:2,
      paddingLeft: 10,
      //width:80,
      fontSize: 12,
      justifyContent: 'flex-end',
      textAlign: 'flex-end',
      color: '#FFFFFF',
      userSelect: 'none',
      opacity: 0.6
    },
    ref: labelTransRef
  })
  const focus = () => {
    labelTransRef.start({
      from: {
        paddingBottom: 20,
        opacity: 0.6
      },
      to: {
        paddingBottom: 16,
        opacity: 1.0
      }
    })
  }
  const blur = () => {
    labelTransRef.start({
      from: {
        paddingBottom: 16,
        opacity: 1.0
      },
      to: {
        paddingBottom: 20,
        opacity: 0.6
      }
    })
  }
  React.useImperativeHandle(ref, () => ({
    focus: () => { focus() },
    blur: () => { blur() },

  }));
  return (
    <animated.div style={{ ...labelTrans, cursor: 'pointer' }} onClick={() => {
      props.scrollToFn();
    }}>
      {props.labelText}
    </animated.div>
  )
}
LabelDiv = React.forwardRef(LabelDiv);




export default (props) => {
  const [index, setIndex] = React.useState(0);
  const [scrollLock, setScrollLock] = React.useState(false);

  const labelDivRefs = [];
  const labelDivs = props.labels.map((label, i) => {
    const labelRef = React.useRef();
    labelDivRefs.push(labelRef);
    return (
      <LabelDiv labelText={label} ref={labelRef} key={`labelDiv${i}`} index={i} scrollToFn={() => { scrollTo(true, i) }} />
    )
  })
  React.useEffect(() => {
    labelDivRefs[0].current.focus();
  }, [])

  React.useEffect(() => {
    if (props.currentIndex > index) {
      scrollRight(false);
    } else if (props.currentIndex < index) {
      scrollLeft(false);
    }
  }, [props.currentIndex])



  const scrollDivTransRef = useSpringRef();
  const scrollDivTrans = useSpring({
    from: {
      width: '100%',
      height: 40,
      textAlign: 'center',
      transform: 'translate(0,0)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 8,
      marginRight:28
    },
    ref: scrollDivTransRef
  })


  const scrollLeft = (isTriggeredLocal) => {
    if (index > 0 && !scrollLock) {
      setScrollLock(true);
      const _index = index - 1;
      labelDivRefs[index].current.blur();
      labelDivRefs[_index].current.focus();
      scrollDivTransRef.start({
        from: {
          //transform: `translate(-${index * 40}px,0)`
        },
        to: {
          //transform: `translate(-${_index * 40}px,0)`
        }
      });
      setIndex(_index);
      if (isTriggeredLocal) {
        props.onScrollUp();
      }
      setTimeout(() => {
        setScrollLock(false)
      }, 1500)
    }
  }

  const scrollRight = (isTriggeredLocal) => {
    if (index < props.labels.length - 1 && !scrollLock) {
      setScrollLock(true);
      const _index = index + 1;
      labelDivRefs[index].current.blur();
      labelDivRefs[_index].current.focus();
      scrollDivTransRef.start({
        from: {
          //transform: `translate(-${index * 40}px,0)`
        },
        to: {
          //transform: `translate(-${_index * 40}px,0)`
        }
      });
      setIndex(_index);
      if (isTriggeredLocal) {
        props.onscrollDown();
      }
      setTimeout(() => {
        setScrollLock(false)
      }, 1500)
    }
  }

  const scrollTo = (isTriggeredLocal, toIndex) => {
    if (!scrollLock) {
      setScrollLock(true);
      const _index = toIndex;
      labelDivRefs[index].current.blur();
      labelDivRefs[_index].current.focus();
      scrollDivTransRef.start({
        from: {
          //transform: `translate(-${index * 40}px,)`
        },
        to: {
          //transform: `translate(-${_index * 40}px,0)`
        }
      });
      setIndex(_index);
      if (isTriggeredLocal) {
        props.pageTo(toIndex);
      }
      setTimeout(() => {
        setScrollLock(false)
      }, 1500)
    }
  }

  return (
    <ReactScrollWheelHandler
      leftHandler={() => { scrollLeft(true) }}
      rightHandler={() => { scrollRight(true) }}
      style={{
        height: 40,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: 12,
        fontFamily: 'SourceHanSerif',
        //...props.style
      }}
    >
      <div style={{
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        maskImage: `url(${horizontalFadeMask})`,
        WebkitMaskImage: `url(${horizontalFadeMask})`,
      }}>
        <animated.div
          //scrollContent
          style={scrollDivTrans}

        >
          {/* <div style={{
                        height: 16
                    }} /> */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>{labelDivs}</div>


          {/* <div style={{
                        height: 104
                    }} /> */}
        </animated.div>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight:12,
        marginTop:-8
      }}>
        <img src={horizontalLine} style={{
          width: 216,
          height: 4,
          userSelect: 'none'
        }} />
      </div>


    </ReactScrollWheelHandler>
  )
}