import './App.css';
//import 'antd/dist/antd.min.css';
import React, { Suspense, Children } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import { motion } from "framer-motion";


import Header from '@Components/Header';


import Splash from '@Pages/PC/Splash';
import CommercialWorks from '@Pages/PC/CommercialWorks';
import OriginalWorks from '@Pages/PC/OriginalWorks';
import Peripherals from '@Pages/PC/Peripherals';
import Reservation from '@Pages/PC/Reservation';

import LoadingPage from '@Pages/LoadingPage';

import reduxStore from '@Redux/Store';


import OverallBGImage from '@Components/OverallBGImage';
import ImageViewer from '@Components/ImageViewer'
import useViewport from '@Utils/UseViewport';

import DataProvider from '@Redux/DataProvider';


const PageLayout = ({ children }) => children;

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 1.2
};

const AnimationLayout = () => {
  const { pathname } = useLocation();
  const { width: windowWidth } = useViewport();
  const getScale = () => {
    if (windowWidth > 2400) {
      return 'scale(1.2,1.2)';
    }
    if (windowWidth > 1900) {
      return 'scale(0.9,0.9)';
    }
    if (windowWidth > 1500) {
      return 'scale(0.8,0.8)';
    }
    if (windowWidth > 1000) {
      return 'scale(0.68,0.68)';
    }
    return 'scale(0.5,0.5)';
  }
  return (
    <PageLayout>
      <motion.div
        style={{ height: '100%', width: '100%', transform: getScale() }}
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
    </PageLayout>
  );
};

//Router淡入淡出 抄的 有空看明白

function App() {
  document.body.addEventListener('touchmove', (e) => {
    e.preventDefault();
  }, { passive: false });
  return (
    <Suspense fallback={<LoadingPage />}>

      <Provider store={reduxStore}>
        <DataProvider>
          <BrowserRouter>
            <div className="App">
              <ImageViewer>
                <OverallBGImage>
                  <Header />
                  <Routes>
                    <Route element={<AnimationLayout />}>
                      <Route path="/" element={<Splash />} />
                      <Route path="/commercial-works" element={<CommercialWorks />} />
                      <Route path="/original-works" element={<OriginalWorks />} />
                      <Route path="/peripherals" element={<Peripherals />} />
                      <Route path="/reservation" element={<Reservation />} />

                    </Route>
                  </Routes>
                </OverallBGImage>
              </ImageViewer>
            </div>
          </BrowserRouter>
        </DataProvider>
      </Provider>

    </Suspense>
  );
}

export default App;
