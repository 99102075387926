import { createSlice } from '@reduxjs/toolkit';

export const BGControlSlice = createSlice({
    name: 'BGControl',
    initialState: {
        src: null,
        imageSrc:null
    },
    reducers: {
        change: (state, action) => {
            state.src = action.payload;
        },
        show:(state,action)=>{
            state.imageSrc=action.payload
        }
    }
});

export const { change,show } = BGControlSlice.actions;
export default BGControlSlice.reducer;