import React from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { saveCommercialWorks, saveOriginalWorks, savePeripherals, saveReservations, saveConfig } from './ReducerSlices/Data';



function getCommercialWorksData() {

    return new Promise(function (resolve, reject) {
        fetch("/api/work/commercial", {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}

function getOriginalWorksData() {
    return new Promise(function (resolve, reject) {
        fetch("/api/work/personal", {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}

function getPeripheralsData() {
    return new Promise(function (resolve, reject) {
        fetch("/api/good", {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}

function getReservationsData() {
    return new Promise(function (resolve, reject) {
        fetch("/api/ticket", {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}

function getConfigData() {
    return new Promise(function (resolve, reject) {
        fetch("/api/config", {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}


export default (props) => {
    const [isReady0, _setReady0] = React.useState(false);
    const [isReady1, _setReady1] = React.useState(false);
    const [isReady2, _setReady2] = React.useState(false);
    const [isReady3, _setReady3] = React.useState(false);
    const [isReady4, _setReady4] = React.useState(false);
    const setReady = (requestId) => {
        switch (requestId) {
            case 0:
                _setReady0(true);
                break;
            case 1:
                _setReady1(true);
                break;
            case 2:
                _setReady2(true);
                break;
            case 3:
                _setReady3(true);
                break;
            case 4:
                _setReady4(true);
                break;
            default:
                break;
        }
    }
    const dispatch = useDispatch();
    React.useEffect(() => {
        getCommercialWorksData().then((res) => {
            if (res.success) {
                dispatch(saveCommercialWorks(res.data));
                setReady(0);
            } else {
                throw (res);
            }
        }).catch((e) => {
            console.log("err::", e);
            if (e.code !== 401) {
                message.error({
                    content: "获取部分资源失败",
                    duration: 1,
                    style: {
                        marginTop: 72
                    }
                })
            }
            setReady(0);
        });



        getOriginalWorksData().then((res) => {
            if (res.success) {
                dispatch(saveOriginalWorks(res.data));
                setReady(1);
            } else {
                throw (res);
            }
        }).catch((e) => {
            console.log("err::", e);
            if (e.code !== 401) {
                message.error({
                    content: "获取部分资源失败",
                    duration: 1,
                    style: {
                        marginTop: 72
                    }
                })
            }
            setReady(1);
        });

        getPeripheralsData().then((res) => {
            if (res.success) {
                dispatch(savePeripherals(res.data));
                setReady(2);
            } else {
                throw (res);
            }
        }).catch((e) => {
            console.log("err::", e);
            if (e.code !== 401) {
                message.error({
                    content: "获取部分资源失败",
                    duration: 1,
                    style: {
                        marginTop: 72
                    }
                })
            }
            setReady(2);
        });

        getReservationsData().then((res) => {
            if (res.success) {
                dispatch(saveReservations(res.data));
                setReady(3);
            } else {
                throw (res);
            }
        }).catch((e) => {
            console.log("err::", e);
            if (e.code !== 401) {
                message.error({
                    content: "获取部分资源失败",
                    duration: 1,
                    style: {
                        marginTop: 72
                    }
                })
            }
            setReady(3);
        });

        getConfigData().then((res) => {
            if (res.success) {
                const _data = JSON.parse(res.data);
                dispatch(saveConfig(_data));
                setReady(4);
            } else {
                throw (res);
            }
        }).catch((e) => {
            console.log("err::", e);
            if (e.code !== 401) {
                message.error({
                    content: "获取部分资源失败",
                    duration: 1,
                    style: {
                        marginTop: 72
                    }
                })
            }
            setReady(4);
        });
    }, [])




    return (isReady0 && isReady1 && isReady2 && isReady3 && isReady4) ? (props.children) : (<div>Now Loading...</div>);
}



