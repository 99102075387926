import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'data',
    initialState: {
        commercialWorks: [],
        originalWorks: [],
        peripherals: [],
        reservations: [],
        config: {}
    },
    reducers: {
        saveConfig: (state, action) => {
            state.config = action.payload;
        },
        saveCommercialWorks: (state, action) => {
            state.commercialWorks = action.payload;
        },
        saveOriginalWorks: (state, action) => {
            state.originalWorks = action.payload;
        },
        savePeripherals: (state, action) => {
            state.peripherals = action.payload;
        },
        saveReservations: (state, action) => {
            state.reservations = action.payload;
        }
    }
});

export const { saveConfig, saveCommercialWorks, saveOriginalWorks, savePeripherals, saveReservations } = dataSlice.actions;
export default dataSlice.reducer;