import React from 'react';
import useViewport from '@Utils/UseViewport';
import { useSelector, useDispatch } from "react-redux";
import { show } from '@Redux/ReducerSlices/BGControl';


export default (props) => {
    let imageSrc = useSelector(state => state.BGControl.imageSrc);
    const dispatch = useDispatch();
    //const [imgSrcs, setImgSrcs] = React.useState([null, null]);
    const [isShow, setShow] = React.useState(false)
    const bgImageStyle = {
        height: '100%',
        width: '100%',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        userSelect: 'none'
    }

    React.useEffect(() => {
        console.log(imageSrc)
        if (imageSrc) {
            setShow(true)
        } else {
            setShow(false)
        }
    }, [imageSrc])


    return (
        <div>

            {isShow ? (<div style={{
                 backgroundColor: '#202020',
                //backgroundColor: 'black',
                
                opacity: 1,
                width: '100%',
                height: '100%',
                position: 'absolute',
            }} ><div style={{
                backgroundImage: `url(${imageSrc})`,
                ...bgImageStyle
            }}
                onClick={() => { dispatch(show(null)) }} /></div>) : null}

            {props.children}

        </div>
    )
}