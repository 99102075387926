import { configureStore } from "@reduxjs/toolkit";

import userInfoReducer from "./ReducerSlices/UserInfo";
import BGControlReducer from "./ReducerSlices/BGControl";
import DataReducer from './ReducerSlices/Data';

export default configureStore({
    reducer: {
        userInfo: userInfoReducer,
        BGControl: BGControlReducer,
        data:DataReducer
    }
})