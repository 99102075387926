import React from 'react';
import { animated, useSpring, useSpringRef } from '@react-spring/web';
import pallette from '@Assets/Styles/pallette';

const testImage = require('@Assets/Images/pic1.webp');


const publicTextStyle = {
  width: 400,
  height: 300,
  position: 'absolute',
  top: 260,
  //boxShadow: '16px 16px 16px #00000080',
  transformOrigin: '1000px center',
}

const styleAtPos0 = {
  zIndex: 2,
  opacity: 1
}
//轮切
const styleAtPos1 = {
  zIndex: 1,
  opacity: 0
}


//const cardStyles = [styleAtPos0, styleAtPos1, styleAtPos2, styleAtPos3, styleAtPos4];

const cardStyles = [styleAtPos1, styleAtPos0];


let TextCarousel = (props, ref) => {

  React.useImperativeHandle(ref, () => ({
    nextPage: () => {
      nextPage();
    },
  }));



  const [index, setIndex] = React.useState(0);

  const [dataReadPointer, setReadPointer] = React.useState(0);

  const [descTexts, setDescTexts] = React.useState([[], []]);
  React.useEffect(() => {

    const _descTexts = [...descTexts];
    _descTexts[0] = props.data.descTexts[0];
    setDescTexts(_descTexts);
    //setReadPointer(3);
  }, [])

  const nextPage = () => {
    let _index = index + 1;
    if (_index > 1) {
      _index = 0;
    }
    setIndex(_index);

    const _pointer = dataReadPointer + 1;
    setReadPointer(_pointer);

    const _descTexts = [...descTexts];
    _descTexts[_index % 2] = props.data.descTexts[_pointer % props.data.descTexts.length];
    setDescTexts(_descTexts);

    trans0Ref.start({
      from: cardStyles[(1 + index) % 2],
      to: cardStyles[(0 + index) % 2]
    });
    trans1Ref.start({
      from: cardStyles[(0 + index) % 2],
      to: cardStyles[(1 + index) % 2]
    });
  }

  const trans0Ref = useSpringRef();
  const trans1Ref = useSpringRef();


  const trans0 = useSpring({
    from: { ...styleAtPos0, ...publicTextStyle },
    ref: trans0Ref
  });
  const trans1 = useSpring({
    from: { ...styleAtPos1, ...publicTextStyle },
    ref: trans1Ref
  });

  return (
    <div style={{
      userSelect: 'none',
      ...props.style,
      //position: 'fixed',
    }}>

      <animated.div style={trans0}>
        <div style={{
          fontFamily: 'SansThin',
          color: pallette.limeGreen,
          fontSize: 60,
          display:'flex',
          flexDirection:'column',
          justifyContent:'flex-end',
          textAlign: 'left',
          lineHeight: 0.8,
          marginTop: -60,
          height:120
        }}
          className="moniqa-display-salt"
        >
          {props.data.title.map((frag, i) => {
            console.log(frag,i)
            return (
              <>
                {(i !== 0) && <br />}
                {frag}
              </>
            )
          })}
        </div>
        <div style={{
          fontFamily: 'SourceHanSerif',
          color: pallette.whiteF0,
          fontSize: 36,
          textAlign: 'left',
          lineHeight: 1,
          marginTop: 8
        }}>
          {props.data.subTitle.map((frag, i) => {
            return (
              <>
                {(i !== 0) && <br />}
                {frag}
              </>
            )
          })}
        </div>
        <div style={{
          fontFamily: 'SourceHanSerif',
          color: pallette.whiteF0,
          fontSize: 12,
          textAlign: 'left',
          lineHeight: 1.2,
          marginTop: 18,
          width: 320
        }}>
          {descTexts[0].map((frag, i) => {
            return (
              <>
                {(i !== 0) && <div style={{ height: 8 }} />}
                {frag}
              </>
            )
          })}
        </div>




      </animated.div>
      <animated.div style={trans1}>
        <div style={{
          fontFamily: 'SansThin',
          color: pallette.limeGreen,
          fontSize: 60,
          display:'flex',
          flexDirection:'column',
          justifyContent:'flex-end',
          textAlign: 'left',
          lineHeight: 0.8,
          marginTop: -60,
          height:120

        }}
          className="moniqa-display-salt"
        >
          {props.data.title.map((frag, i) => {
            return (
              <>
                {(i !== 0) && <br />}
                {frag}
              </>
            )
          })}
        </div>

        <div style={{
          fontFamily: 'SourceHanSerif',
          color: pallette.whiteF0,
          fontSize: 36,
          textAlign: 'left',
          lineHeight: 1,
          marginTop:8
        }}>
          {props.data.subTitle.map((frag, i) => {
            return (
              <>
                {(i !== 0) && <br />}
                {frag}
              </>
            )
          })}
        </div>
        <div style={{
          fontFamily: 'SourceHanSerif',
          color: pallette.whiteF0,
          fontSize: 12,
          textAlign: 'left',
          lineHeight: 1.2,

          marginTop: 18,
          width: 320
        }}>
          {descTexts[1].map((frag, i) => {
            return (
              <>
                {(i !== 0) && <div style={{ height: 8 }} />}
                {frag}
              </>
            )
          })}
        </div>


      </animated.div>
    </div>
  )
}

TextCarousel = React.forwardRef(TextCarousel);
export default TextCarousel;