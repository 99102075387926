import React from 'react';

import PageScroll from 'react-page-scroller';


import { animated, useSpring, useSpringRef, config } from "@react-spring/web";

import PageScrollIndicator from '@Components/PageScrollIndicator';
import CombinedCardWithText from '@Components/CombinedCardWithText';
import CombinedTachieWithText from '@Components/CombinedTachieWithText';

import pallette from '@Assets/Styles/pallette';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";

import { useSelector } from 'react-redux';







let PageBlockType1 = (props, ref) => {
    const [focused, setFocused] = React.useState(props.isFirst);
    const [autoPlay, setAutoPlay] = React.useState(true);
    const cutInStyleRef = useSpringRef();
    const cutInStyle = useSpring({
        ref: cutInStyleRef
    })
    React.useImperativeHandle(ref, () => ({
        cutIn: () => {
            setAutoPlay(true);
            setFocused(true);
            setTimeout(() => {
                cutInStyleRef.start({
                    from: { opacity: 0 },
                    to: { opacity: 1 }
                })
            }, 500)

        },
        cutOut: () => {
            setAutoPlay(false);
            cutInStyleRef.start({
                from: { opacity: 1 },
                to: { opacity: 0 }
            });
            setTimeout(() => {
                setFocused(false);
            }, 500)
        }
    }));

    return (
        (focused) ? (
            <animated.div style={cutInStyle}>
                <div style={{ height: 120 }} />
                <CombinedCardWithText data={{
                    imgSrcs: props.data.works.map((item, i) => (item.image)),
                    cardTexts: props.data.works.map((item, i) => (item.title)),
                    descTitle: props.data.title.split(/[\,\，]/).map((item, i) => (item)),
                    descSubTitle: props.data.subtitle.split(/[\,\，]/).map((item, i) => (item)),
                    descTexts: props.data.works.map((item, i) => {
                        return (
                            item.desc.split(`\n`).map((item, i) => (item))
                        )
                    })
                }}
                    autoPlay={autoPlay}
                />
            </animated.div>
        ) : (
                <div style={{
                    height: 960
                }}>

                </div>
            )

    )
}

let PageBlockType2 = (props, ref) => {
    //console.log(props.data);
    const [focused, setFocused] = React.useState(props.isFirst);
    const [autoPlay, setAutoPlay] = React.useState(true);

    const cutInStyleRef = useSpringRef();
    const cutInStyle = useSpring({
        ref: cutInStyleRef
    })
    React.useImperativeHandle(ref, () => ({
        cutIn: () => {
            setFocused(true);
            setAutoPlay(true);
            setTimeout(() => {
                cutInStyleRef.start({
                    from: { opacity: 0 },
                    to: { opacity: 1 }
                })
            }, 500)
        },
        cutOut: () => {
            setAutoPlay(false);
            cutInStyleRef.start({
                from: { opacity: 1 },
                to: { opacity: 0 }
            });
            setTimeout(() => {
                setFocused(false);
            }, 500)
        }
    }));

    return (
        (focused) ? (
            <animated.div style={cutInStyle}>
                <div style={{ height: 120 }} />
                <CombinedTachieWithText data={{
                    imgSrcs: props.data.works.map((item, i) => (item.image)),
                    tachieCateNames: props.data.works.map((item, i) => (item.title)),
                    tachieCharaNames: props.data.works.map((item, i) => (item.subtitle)),
                    descTitle: props.data.title.split(/[\,\，]/).map((item, i) => (item)),
                    descSubTitle: props.data.subtitle.split(/[\,\，]/).map((item, i) => (item)),
                    descTexts: props.data.works.map((item, i) => {
                        return (
                            item.desc.split(`\n`).map((item, i) => (item))
                        )
                    })
                }}
                    autoPlay={autoPlay}
                />
            </animated.div>
        ) : (
                <div style={{
                    height: 960
                }}>

                </div>
            )

    )
}


PageBlockType1 = React.forwardRef(PageBlockType1);

PageBlockType2 = React.forwardRef(PageBlockType2);



export default () => {
    const _data = useSelector(state => state.data.commercialWorks);
    const data = [..._data].sort((a, b) => (a.pos - b.pos));
    const pageRefs = React.useRef([]);
    React.useEffect(() => {
        pageRefs.current = pageRefs.current.slice(0, data.length);
    }, [data])
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [scrollLock, setScrollLock] = React.useState(false);

    const pageDown = () => {
        const _index = currentIndex + 1;
        if (_index < data.length && !scrollLock) {
            pageRefs.current[_index].cutIn();
            pageRefs.current[currentIndex].cutOut();
            setCurrentIndex(_index);
            setScrollLock(true);
            setTimeout(() => {
                setScrollLock(false);
            }, 1500);
        }
    }

    const pageUp = () => {
        const _index = currentIndex - 1;
        if (_index >= 0 && !scrollLock) {
            pageRefs.current[_index].cutIn();
            pageRefs.current[currentIndex].cutOut();
            setCurrentIndex(_index);
            setScrollLock(true);
            setTimeout(() => {
                setScrollLock(false);
            }, 1500);
        }
    }


    const pageTo = (toIndex) => {
        const _index = toIndex;
        console.log(pageRefs.current);
        if (_index >= 0 && !scrollLock) {
            pageRefs.current[_index].cutIn();
            pageRefs.current[currentIndex].cutOut();
            setCurrentIndex(_index);
            setScrollLock(true);
            setTimeout(() => {
                setScrollLock(false);
            }, 1500);
        }
    }

    return (
        <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column'
        }}>
            <div style={{ flexGrow: 1 }} >
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
                <div style={{
                    width: 208,
                    height: 750,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: 'flex-start',
                }}>

                    <PageScrollIndicator
                        onScrollUp={() => {
                            pageUp();
                        }}
                        onScrollDown={() => {
                            pageDown();
                        }}
                        pageTo={pageTo}
                        labels={data.map((item, i) => (item.text))}
                        currentIndex={currentIndex}
                    />
                </div>
                <ReactScrollWheelHandler
                    upHandler={() => {
                        pageUp();

                    }}
                    downHandler={() => {
                        pageDown();
                    }}
                >
                    <PageScroll
                        renderAllPagesOnFirstRender={true}
                        containerWidth={1440}
                        containerHeight={960}
                        blockScrollDown={true}
                        blockScrollUp={true}
                        customPageNumber={currentIndex}
                    >
                        {
                            data.map((item, i) => {
                                let blockData = item;
                                let BlockComponent;
                                if (blockData.type === 0) {
                                    BlockComponent = <PageBlockType1 isFirst={i === 0} ref={el => pageRefs.current[i] = el} data={blockData} key={`pageBlock${i}`} />;
                                } else if (blockData.type === 1) {
                                    BlockComponent = <PageBlockType2 isFirst={i === 0} ref={el => pageRefs.current[i] = el} data={blockData} key={`pageBlock${i}`} />;
                                }
                                return BlockComponent;
                            })
                        }
                    </PageScroll>
                </ReactScrollWheelHandler>

            </div>
            <div style={{ flexGrow: 1 }}>
                {/* 立绘轮播模式下的额外黑边 */}
                {(data[currentIndex].type === 1) &&//设定在哪几个index显示
                    <div
                        style={{
                            position: 'absolute',
                            width: 2560 + 720,
                            height: 1440,
                            backgroundColor: '#000000D0',
                            transform: 'translate(-720px,-280px)',
                            zIndex: -1
                        }} />
                }
            </div>
        </div>
    )
}