import React from 'react';
import { animated, useSpring, useSpringRef } from '@react-spring/web';
import SquareIcon from '@mui/icons-material/Square';
import pallette from '@Assets/Styles/pallette';
import useImagePreloader from "@Utils/useImagePreloader"
import { useDispatch } from 'react-redux';
import { show } from '@Redux/ReducerSlices/BGControl';


const TachieLabel = (props) => {
    return (
        <div style={{
            width: 240,
            height: 80,
            //backgroundColor: 'lime',
            borderRadius: 4,
            backgroundImage: 'linear-gradient(to right,#00000060,#00000000)',
            position: 'absolute',
            top: 400,
            right: -64,
            zIndex: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',

        }}>
            <SquareIcon style={{
                position: 'absolute',
                top: 18,
                left: 8,
                color: pallette.limeGreen,
                fontSize: 14
            }} />
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'white',
                fontFamily: 'SourceHanSans',
                marginLeft: 28,
                fontSize: 20

            }}>
                {props.tachieCateName}
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'white',
                fontFamily: 'SourceHanSans',
                marginLeft: 28,
                fontSize: 22
            }}>
                {props.tachieCharaName}
            </div>
        </div>
    )
}




const picStyle = { width: 720, height: '100%', objectFit: 'contain', userSelect: 'none' }

const publicTachieStyle = {
    position: 'absolute',
    //backgroundColor: '#ff000030',
    //marginTop: 144,
    width: 720,
    height: 960,
    marginLeft: 24,
    bottom: -1080 //960+120(立绘溢出布局的部分)
}

const styleAtPos0 = {
    zIndex: 5,
    opacity: 1,

}
const styleAtPos1 = {
    zIndex: 4,
    opacity: 0,
}
const styleAtPos2 = {
    zIndex: 3,
    opacity: 0,
}
//和文字不同
//2图互切的策略在浏览器性能菜鸡的时候会出现偶发闪烁bug（渲染先于加载，闪了一下换图之前的图）
//  xxxx 3图互切可以保证图切出来时必加载完
//好像还是不行，实在不行给切换加个延时
//也好给以后改动画留出接口


const tachieStyles = [styleAtPos2, styleAtPos1, styleAtPos0];


let TachieCarousel = (props, ref) => {
    const dispatch = useDispatch();
    useImagePreloader(props.data.imgSrcs)
    React.useImperativeHandle(ref, () => ({
        nextPage: () => {
            nextPage();
        },
        getDisplayingImage: () => {
            return props.data.imgSrcs[(dataReadPointer + 1) % props.data.imgSrcs.length];
        }
    }));

    //翻页方法暴露给外部

    const [index, setIndex] = React.useState(0);

    //用于管理滚动状态的index

    const [dataReadPointer, setReadPointer] = React.useState(0);

    //点击查看大图
    const showImg = (value) => {
        // console.log(value)
        // console.log(this.current.getDisplayingImage())
        // console.log((dataReadPointer - 2) % props.data.imgSrcs.length)
        //const newBG = props.data.imgSrcs[(dataReadPointer - 2) % props.data.imgSrcs.length];
        dispatch(show(imgSrcs[value]));
    }
    //设置一个用来从图片列表读值的指针，滚动播放用

    const [imgSrcs, setImgSrcs] = React.useState([null, null, null]);
    const [tachieCateNames, setTachieCateNames] = React.useState([null, null, null]);
    const [tachieCharaNames, setTachieCharaNames] = React.useState([null, null, null]);
    //内部建图片地址,描述文字Array
    React.useEffect(() => {
        const _imgSrcs = [...imgSrcs];
        const _tachieCateNames = [...tachieCateNames];
        const _tachieCharaNames = [...tachieCharaNames];

        _imgSrcs[0] = props.data.imgSrcs[0];
        _imgSrcs[1] = props.data.imgSrcs[1];
        setImgSrcs(_imgSrcs);

        _tachieCateNames[0] = props.data.tachieCateNames[0];
        _tachieCateNames[1] = props.data.tachieCateNames[1];
        setTachieCateNames(_tachieCateNames);

        _tachieCharaNames[0] = props.data.tachieCharaNames[0];
        _tachieCharaNames[1] = props.data.tachieCharaNames[1];
        setTachieCharaNames(_tachieCharaNames);
        //setReadPointer(1);
        //就俩，不特意写个循环了
        //循环写法去cardcarousel里找
    }, [])



    const nextPage = () => {
        let _index = index + 1;
        if (_index > 2) {
            _index = 0;
        }
        setIndex(_index);

        const _pointer = dataReadPointer + 1;
        setReadPointer(_pointer);

        const _imgSrcs = [...imgSrcs];
        const _tachieCateNames = [...tachieCateNames];
        const _tachieCharaNames = [...tachieCharaNames];

        _imgSrcs[_index % 3] = props.data.imgSrcs[_pointer % props.data.imgSrcs.length];
        setImgSrcs(_imgSrcs);

        _tachieCateNames[_index % 3] = props.data.tachieCateNames[_pointer % props.data.tachieCateNames.length];
        setTachieCateNames(_tachieCateNames);

        _tachieCharaNames[_index % 3] = props.data.tachieCharaNames[_pointer % props.data.tachieCharaNames.length];
        setTachieCharaNames(_tachieCharaNames);

        trans0Ref.start({
            from: tachieStyles[(2 + index) % 3],
            to: tachieStyles[(0 + index) % 3]
        });
        trans1Ref.start({
            from: tachieStyles[(1 + index) % 3],
            to: tachieStyles[(2 + index) % 3]
        });
        trans2Ref.start({
            from: tachieStyles[(0 + index) % 3],
            to: tachieStyles[(1 + index) % 3]
        });

    }

    const trans0Ref = useSpringRef();
    const trans1Ref = useSpringRef();
    const trans2Ref = useSpringRef();




    const trans0 = useSpring({
        from: { ...styleAtPos0, ...publicTachieStyle },
        ref: trans0Ref
    });
    const trans1 = useSpring({
        from: { ...styleAtPos1, ...publicTachieStyle },
        ref: trans1Ref
    });
    const trans2 = useSpring({
        from: { ...styleAtPos2, ...publicTachieStyle },
        ref: trans2Ref
    });
    //定义动画hook与ref

    return (
        <div style={{
            userSelect: 'none',
            ...props.style,

        }} onClick={()=>{showImg(index)}}>
            <animated.div style={trans0}>
                <TachieLabel
                    tachieCharaName={tachieCharaNames[0]}
                    tachieCateName={tachieCateNames[0]}
                />

                <img src={imgSrcs[0]} style={{ ...picStyle }} />
            </animated.div>
            <animated.div style={trans1}>
                <TachieLabel
                    tachieCharaName={tachieCharaNames[1]}
                    tachieCateName={tachieCateNames[1]}
                />

                <img src={imgSrcs[1]} style={{ ...picStyle }} />
            </animated.div>
            <animated.div style={trans2}>
                <TachieLabel
                    tachieCharaName={tachieCharaNames[2]}
                    tachieCateName={tachieCateNames[2]}
                />

                <img src={imgSrcs[2]} style={{ ...picStyle }} />
            </animated.div>
        </div>
    )
}

TachieCarousel = React.forwardRef(TachieCarousel);
export default TachieCarousel;