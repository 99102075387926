import { LoremIpsum } from "lorem-ipsum";

const lorem = new LoremIpsum({
    sentencesPerParagraph: {
        max: 6,
        min: 3
    },
    wordsPerSentence: {
        max: 8,
        min: 4
    }
})


const dummyWords = (count) => {
    return lorem.generateWords(count);
}
const dummySentences = (count) => {
    return lorem.generateSentences(count);
}
const dummyParagraphs = (count) => {
    return lorem.generateParagraphs(count);
}

export { dummyWords, dummySentences, dummyParagraphs };


export default lorem;


