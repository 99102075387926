import React from 'react';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import verticalLine from '@Assets/Images/verticalLine.png';
import verticalFadeMask from '@Assets/Images/verticalFadeMask.png';

import { animated, useSpring, useSpringRef } from "@react-spring/web";


let LabelDiv = (props, ref) => {
    const labelTransRef = useSpringRef();
    const labelTrans = useSpring({
        from: {
            height: 40,
            paddingRight: 20,
            fontSize: 20,
            color: '#FFFFFF',
            userSelect: 'none',
            opacity: 0.6
        },
        ref: labelTransRef
    })
    const focus = () => {
        labelTransRef.start({
            from: {
                paddingRight: 20,
                opacity: 0.6
            },
            to: {
                paddingRight: 16,
                opacity: 1.0
            }
        })
    }
    const blur = () => {
        labelTransRef.start({
            from: {
                paddingRight: 16,
                opacity: 1.0
            },
            to: {
                paddingRight: 20,
                opacity: 0.6
            }
        })
    }
    React.useImperativeHandle(ref, () => ({
        focus: () => { focus() },
        blur: () => { blur() },
    }));
    return (
        <animated.div style={{ ...labelTrans, cursor: 'pointer' }} onClick={() => {
            props.scrollToFn();
        }}>
            {props.labelText}
        </animated.div>
    )
}
LabelDiv = React.forwardRef(LabelDiv);




export default (props) => {
    const [index, setIndex] = React.useState(0);
    const [scrollLock, setScrollLock] = React.useState(false);

    const labelDivRefs = [];
    const labelDivs = props.labels.map((label, i) => {
        const labelRef = React.useRef();
        labelDivRefs.push(labelRef);
        return (
            <LabelDiv labelText={label} ref={labelRef} key={`labelDiv${i}`} index={i} scrollToFn={() => { scrollTo(true, i) }} />
        )
    })
    React.useEffect(() => {
        labelDivRefs[0].current.focus();
    }, [])

    React.useEffect(() => {
        if (props.currentIndex > index) {
            scrollDown(false);
        } else if (props.currentIndex < index) {
            scrollUp(false);
        }
    }, [props.currentIndex])



    const scrollDivTransRef = useSpringRef();
    const scrollDivTrans = useSpring({
        from: {
            width: '100%',
            height: 240,
            textAlign: 'right',
            transform: 'translate(0,0)'
        },
        ref: scrollDivTransRef
    })


    const scrollUp = (isTriggeredLocal) => {
        if (index > 0 && !scrollLock) {
            setScrollLock(true);
            const _index = index - 1;
            labelDivRefs[index].current.blur();
            labelDivRefs[_index].current.focus();
            scrollDivTransRef.start({
                from: {
                    transform: `translate(0,-${index * 40}px)`
                },
                to: {
                    transform: `translate(0,-${_index * 40}px)`
                }
            });
            setIndex(_index);
            if (isTriggeredLocal) {
                props.onScrollUp();
            }
            setTimeout(() => {
                setScrollLock(false)
            }, 1500)
        }
    }

    const scrollDown = (isTriggeredLocal) => {
        if (index < props.labels.length - 1 && !scrollLock) {
            setScrollLock(true);
            const _index = index + 1;
            labelDivRefs[index].current.blur();
            labelDivRefs[_index].current.focus();
            scrollDivTransRef.start({
                from: {
                    transform: `translate(0,-${index * 40}px)`
                },
                to: {
                    transform: `translate(0,-${_index * 40}px)`
                }
            });
            setIndex(_index);
            if (isTriggeredLocal) {
                props.onScrollDown();
            }
            setTimeout(() => {
                setScrollLock(false)
            }, 1500)
        }
    }

    const scrollTo = (isTriggeredLocal, toIndex) => {
        if (!scrollLock) {
            setScrollLock(true);
            const _index = toIndex;
            labelDivRefs[index].current.blur();
            labelDivRefs[_index].current.focus();
            scrollDivTransRef.start({
                from: {
                    transform: `translate(0,-${index * 40}px)`
                },
                to: {
                    transform: `translate(0,-${_index * 40}px)`
                }
            });
            setIndex(_index);
            if (isTriggeredLocal) {
                props.pageTo(toIndex);
            }
            setTimeout(() => {
                setScrollLock(false)
            }, 1500)
        }
    }

    return (
        <ReactScrollWheelHandler
            upHandler={() => { scrollUp(true) }}
            downHandler={() => { scrollDown(true) }}
            style={{
                width: 172,
                height: 240,
                marginTop: 24,
                display: 'flex',
                flexDirection: 'row',
                fontSize: 18,
                fontFamily: 'SourceHanSerif'
                //...props.style
            }}
        >
            <div style={{
                display: 'flex',
                flexGrow: 1,
                height: '100%',
                maskImage: `url(${verticalFadeMask})`,
                WebkitMaskImage: `url(${verticalFadeMask})`,
            }}>
                <animated.div
                    //scrollContent
                    style={scrollDivTrans}

                >
                    <div style={{
                        height: 96
                    }} />

                    {labelDivs}

                    <div style={{
                        height: 104
                    }} />
                </animated.div>
            </div>
            <img src={verticalLine} style={{
                width: 4,
                height: 240,
                userSelect: 'none'
            }} />

        </ReactScrollWheelHandler>
    )
}