import React from 'react';
import useViewport from '@Utils/UseViewport';
import { useSelector } from "react-redux";
import { animated, useSpring, useSpringRef } from "@react-spring/web";


const styleAtPos0 = {
    opacity: 0.15,
    zIndex: -1
}
//轮切
const styleAtPos1 = {
    opacity: 0,
    zIndex: -2
}

const BGStyles = [styleAtPos1, styleAtPos0];

const bgImageStyle = {
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
}

const publicContainerStyle = {
    zIndex: -1, position: 'fixed', height: '100%', width: '100%'
}

export default (props) => {
    const src = useSelector(state => state.BGControl.src);
    const [imgSrcs, setImgSrcs] = React.useState([null, null]);

    const bgImageStyle = {
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        userSelect: 'none'
    }

    React.useEffect(() => {
        nextPage();
    }, [src])

    const [index, setIndex] = React.useState(0);

    const nextPage = () => {
        let _index = index + 1;
        if (_index > 1) {
            _index = 0;
        }
        setIndex(_index);

        const _imgSrcs = [...imgSrcs];
        _imgSrcs[(1 + index) % 2] = src;
        setImgSrcs(_imgSrcs);

        trans0Ref.start({
            from: BGStyles[(1 + index) % 2],
            to: BGStyles[(0 + index) % 2]
        });
        trans1Ref.start({
            from: BGStyles[(0 + index) % 2],
            to: BGStyles[(1 + index) % 2]
        });
    }

    const trans0Ref = useSpringRef();
    const trans1Ref = useSpringRef();

    const trans0 = useSpring({
        from: { ...styleAtPos0, ...publicContainerStyle },
        ref: trans0Ref
    });
    const trans1 = useSpring({
        from: { ...styleAtPos1, ...publicContainerStyle },
        ref: trans1Ref
    });

    return (
        <div style={{
            backgroundColor: '#202020',
            zIndex: -3,
            opacity: 1,
            width: '100%',
            height: '100%',
            position: 'fixed',
        }}>
            <animated.div style={trans0}>
                <div style={{
                    backgroundImage: `url(${imgSrcs[0]})`,
                    ...bgImageStyle
                }} />
            </animated.div>
            <animated.div style={trans1}>
                <div style={{
                    backgroundImage: `url(${imgSrcs[1]})`,
                    ...bgImageStyle
                }} />
            </animated.div>
            {props.children}
        </div>
    )
}