import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Box, AppBar, Toolbar, Typography, Button, ButtonGroup, IconButton, InputBase, Menu, MenuItem, Divider, Drawer } from '@mui/material';
import { animated, useSpring, useSpringRef, config } from '@react-spring/web';
import { useSelector } from 'react-redux';

import pallette from '@Assets/Styles/pallette';

import MenuIcon from '@mui/icons-material/Menu';
//import { Drawer } from 'antd';
//import 'antd/dist/antd.css';

const headerStyle = {
  height: 64,
  display: 'flex', flexDirection: 'row', alignItems: 'center'
}

const labelHighlightedStyle = {
  height: 40,
  width: 132,
  //backgroundColor: pallette.limeGreen,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: pallette.limeGreen,
  borderRadius: '0px 0px 4px 4px',
  fontSize: 18,
  fontFamily: 'SourceHanSans',
  userSelect: 'none',
  cursor: 'pointer',
}

const labelDefaultStyle = {
  height: 40,
  width: 132,
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: '#FFFFFF',
  borderRadius: '0px 0px 4px 4px',
  fontSize: 18,
  fontFamily: 'SourceHanSans',
  userSelect: 'none',
  cursor: 'pointer',
  color: pallette.gray8E,
}

const NavigateLabel = (props) => {
  const [highlighted, setHighlighted] = React.useState(false);
  const labelTransRef = useSpringRef();
  const labelTrans = useSpring({
    from: { ...labelDefaultStyle },
    ref: labelTransRef
  })

  const highlight = () => {
    labelTransRef.start({
      from: { ...labelDefaultStyle },
      to: { ...labelHighlightedStyle },
      config: config.slow
    });
    setHighlighted(true);
  }
  const deHighlight = () => {
    labelTransRef.start({
      from: { ...labelHighlightedStyle },
      to: { ...labelDefaultStyle },
      config: config.slow
    });
    setHighlighted(false);
  }
  React.useEffect(() => {
    if (
      props.currentPath === props.path &&
      !highlighted
    ) {
      highlight();
    }
    if (
      props.currentPath !== props.path &&
      highlighted
    ) {
      deHighlight();
    }
  }, [props.currentPath])
  const navigate = useNavigate();
  return (
    <animated.div
      style={{
        ...labelTrans
      }}
      onClick={() => {
        navigate(props.path);
        props.closeDrawer();
      }}
    >
      {props.label}
      <div style={{ height: 6 }} />
    </animated.div>
  )
}



const Header = (props) => {
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const [prevPath, setPrevPath] = React.useState('/');
  const location = useLocation();
  const config = useSelector(state => state.data.config);
  React.useEffect(() => {
    if (prevPath !== location.pathname) {
      if (location.pathname !== '/') {
        if (prevPath === '/') {
          showHeader();
        }
      } else {
        hideHeader();
      }
      setPrevPath(location.pathname);
    }
  }, [location]);

  const headerTransRef = useSpringRef();
  const headerTrans = useSpring({
    from: {
      transform: 'translate(0px,-66px)',
      backgroundColor: '#00000000'
    },
    ref: headerTransRef
  });
  const showHeader = () => {
    headerTransRef.start({
      from: {
        transform: 'translate(0px,-66px)',
        backgroundColor: '#00000000'
      },
      to: {
        transform: 'translate(0px,0px)',
        backgroundColor: '#00000000'
      }
    })
  }

  const hideHeader = () => {
    headerTransRef.start({
      from: {
        transform: 'translate(0px,0px)',
        backgroundColor: '#00000000'
      },
      to: {
        transform: 'translate(0px,-66px)',
        backgroundColor: '#00000000'
      }
    })
  }



  return (
    <>
      <Drawer
        open={drawerVisible}
        anchor={'left'}
        onClose={() => { setDrawerVisible(false) }}
        width={160}
        headerStyle={{
          height: 0,
          width: 0,
        }}
        // contentWrapperStyle={{
        //   boxShadow: '-4px 0px 4px  #000000A0',
        // }}
        PaperProps={{
          style: {
            backgroundColor:'rgba(0,0,0,0.8)'
          }
        }}
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: 16,
          backgroundPosition: '18% 40%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          marginTop: -36,
          boxShadow: '-4px 0px 4px  #000000A0',
        }}
      >
        <div style={{ width: '100%', height: '100%',  }}>
          <div style={{height:80}}></div>
          <NavigateLabel label={'商业作品'} currentPath={location.pathname} path={'/commercial-works'} closeDrawer={() => { setDrawerVisible(false) }} />
          <NavigateLabel label={'个人作品'} currentPath={location.pathname} path={'/original-works'} closeDrawer={() => { setDrawerVisible(false) }} />
          {config.goodsPageVisible ? (
            <NavigateLabel label={'在售周边'} currentPath={location.pathname} path={'/peripherals'} closeDrawer={() => { setDrawerVisible(false) }} />
          ) : (null)}
          <NavigateLabel label={'稿件邀约'} currentPath={location.pathname} path={'/reservation'} closeDrawer={() => { setDrawerVisible(false) }} />
          <NavigateLabel label={'作者信息'} currentPath={location.pathname} path={'/'} closeDrawer={() => { setDrawerVisible(false) }} />
        </div>

      </Drawer>
      {/* <animated.div style={{ ...headerStyle, ...headerTrans }}>
                <div style={{ flexGrow: 3 }} />
                <NavigateLabel label={'商业作品'} currentPath={location.pathname} path={'/commercial-works'} />
                <NavigateLabel label={'个人作品'} currentPath={location.pathname} path={'/original-works'} />
                {config.goodsPageVisible ? (
                    <NavigateLabel label={'在售周边'} currentPath={location.pathname} path={'/peripherals'} />
                ) : (null)}
                <NavigateLabel label={'稿件邀约'} currentPath={location.pathname} path={'/reservation'} />
                <NavigateLabel label={'作者信息'} currentPath={location.pathname} path={'/'} />
                <div style={{ flexGrow: 1 }} />
            </animated.div> */}
      <div
        style={{ width: 60, height: 52, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}
        onClick={() => { setDrawerVisible(true); console.log('clickclick') }}
      >
        <MenuIcon style={{ fontSize: 24, color: pallette.limeGreen }} />
      </div>

    </>

  )
}
export default Header;


