import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { change } from '@Redux/ReducerSlices/BGControl';

import { useSpring, useSpringRef, animated, config } from '@react-spring/web';

import pallette from '@Assets/Styles/pallette';

import introBG from '@Assets/Images/IntroBG.jfif';



const contentContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    //backgroundColor: '#FF303030'
}

const titleContainerStyle = {
    width: 540,
    //height: 360,
    //backgroundColor: '#30ff3030',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    fontFamily: 'SansThin',
    color: pallette.limeGreen,
    lineHeight: 0.8,
    letterSpacing: 2,
    //fontStrech:'[0,]',
    fontSize: 128,
    textAlign: 'left'
}

const descContainerStyle = {
    width: 720,
    height: '100%',
    //backgroundColor: '#3030ff30',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',



}

const descTextStyle = {
    fontFamily: 'SourceHanSerif',
    color: '#FFFFFF',
    fontSize: 18,
    textAlign: 'left',
    marginTop: 18
}

const startButtonStyle = {
    marginBottom: 10,
    fontFamily: 'SansThin',
    color: pallette.limeGreen,
    fontSize: 28,
    textAlign: 'left'
}



const Splash = () => {
    React.useEffect(() => {
        console.log('suspense fn');
    }, [])
    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    // React.useEffect(() => {
    //     dispatch(change(introBG));
    // }, []);

    // const titleTrans = useSpring({
    //     from: {
    //         transform: 'translate(-120px,0px)',
    //         opacity: 0.0
    //     },
    //     to: {
    //         transform: 'translate(0px,0px)',
    //         opacity: 1.0
    //     },
    //     clamp: true,
    //     delay: 200,
    //     config: config.molasses
    // });

    // const descTrans = useSpring({
    //     from: {
    //         transform: 'translate(-120px,0px)',
    //         opacity: 0.0
    //     },
    //     to: {
    //         transform: 'translate(0px,0px)',
    //         opacity: 1.0
    //     },
    //     clamp: true,
    //     delay: 1000,
    //     config: config.molasses
    // });

    // //const startButtonTransRef = useSpringRef();
    // const startButtonTrans = useSpring({
    //     from: {
    //         transform: 'translate(-120px,0px)',
    //         opacity: 0.0
    //     },
    //     to: {
    //         transform: 'translate(0px,0px)',
    //         opacity: 1.0
    //     },
    //     clamp: true,
    //     delay: 1800,
    //     config: config.molasses,
    //     //ref: startButtonTransRef,
    //     // onRest: () => {
    //     //     console.log('fadein complete');
    //     //     // setTimeout(() => {

    //     //     // }, 300);
    //     // }
    // });

    // const breatheTrans = useSpring({
    //     from: { opacity: 0.2 },
    //     to: { opacity: 1.0 },
    //     loop: { reverse: true },
    //     delay: 2600,
    //     config: {
    //         mass: 1,
    //         tension: 0.2,
    //         friction: 0.2,
    //         clamp: true,
    //     }
    // })

    return (
        <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            userSelect: 'none'
        }}>
            Loading...
            {/* <div style={{ flexGrow: 1 }} >
            </div>

            <div style={contentContainerStyle} >
                <div style={
                    titleContainerStyle
                }>
                    <animated.div style={titleTrans} className="moniqa-display-salt">
                        Welcome,<br />
                        visitor.
                    </animated.div>
                </div>
                <div style={descContainerStyle} >
                    <animated.div style={{ ...descTextStyle, ...descTrans }}>
                        你好，这里是TS的个人网站。<div style={{ height: 4 }} />
                        您可以在这里阅览TS的作品，查看相关衍生品信息，填写约稿需求，或欣赏两亿的可爱照片。<div style={{ height: 4 }} />
                        简单写点有的没的个人介绍吧占位。
                    </animated.div>
                    <animated.div style={{ ...startButtonStyle, ...startButtonTrans }} >
                        <animated.div
                            onClick={() => {
                                navigate('/commercial-works')
                            }}
                            style={{ cursor: 'pointer', ...breatheTrans }}
                            className="moniqa-display-salt"
                        >
                            TAP TO START
                        </animated.div>
                    </animated.div>
                </div>
            </div>

            <div style={{ flexGrow: 1 }} >
            </div> */}
        </div >
    )
}

export default Splash;